import { slideshowView } from '@shared/visitor/slideshow/index.js';
export function bindUeSlider(e, terminalName = '', options = {}) {
    const { disableContextMenu = false } = options;
    let tagName = e.target.tagName;
    if (typeof tagName == 'string' && tagName.toLowerCase() == 'img' && e.target.className != 'csssprite') {
        let imgSrc = e.target.src || '';
        let $jumpATag = $(e.target).parents('a');
        if (!imgSrc || ($jumpATag.length > 0 && $jumpATag.attr('href') && $jumpATag.attr('href').length > 0)) {
            return;
        }
        slideshowView({
            id: `${parseInt(Math.random() * 10000)}`,
            previewSrcList: [
                {
                    src: imgSrc,
                },
            ],
            initialIndex: 0,
            logEventConfig: {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: terminalName,
                    jz_version: window._store.state.jzVersion,
                },
            },
            disableContextMenu,
        });
    }
}

export const ueHoverStyle = function () {
    var linkHoverStyle = $('a[aStyle_h]'),
        editorBody = this?.body ? this?.body : this?.editor ? this?.editor?.body : false,
        textNodeParentCss,
        colorCache,
        underlineCache,
        isLinkUnderline = true,
        hoverLen,
        leaveLen;

    if (editorBody) {
        linkHoverStyle = $(editorBody).find('a[aStyle_h]');
    }
    if (linkHoverStyle.length == 0) {
        return;
    }

    for (var i = 0; i < linkHoverStyle.length; i++) {
        $(linkHoverStyle[i])
            .off('mouseenter.hoverStyle')
            .off('mouseleave.hoverStyle')
            .on('mouseenter.hoverStyle', enter)
            .on('mouseleave.hoverStyle', leave);
    }
    // 减少函数副本 by jser 2017-8-31
    function enter() {
        var hoverStyle = $.parseJSON($(this).attr('stylehover')),
            wbackColor = $.parseJSON($(this).attr('wbackColor_h'));

        if (hoverStyle == null && wbackColor == null) {
            return;
        } else if (hoverStyle != null && wbackColor == null) {
            for (var j = 0; (hoverLen = this.childNodes.length), j < hoverLen; j++) {
                var child = this.childNodes[j];
                checkAndSetStyle(child, 1, hoverStyle, null, false);
            }

            underlineCache = this.style.textDecoration;
            colorCache = this.style.color;
            $(this).css({
                'text-decoration': hoverStyle.u ? 'underline' : 'none',
                color: hoverStyle.c,
                display: 'inline-block',
                'text-indent': 0,
            });

            if (!hoverStyle.u) {
                isLinkUnderline = false;
            }
        } else if (wbackColor != null && hoverStyle == null) {
            for (let j = 0; (hoverLen = this.childNodes.length), j < hoverLen; j++) {
                const child = this.childNodes[j];
                checkAndSetStyle(child, 2, null, wbackColor, false);
            }
        } else {
            underlineCache = this.style.textDecoration;
            colorCache = this.style.color;
            for (let j = 0; (hoverLen = this.childNodes.length), j < hoverLen; j++) {
                const child = this.childNodes[j];
                checkAndSetStyle(child, 3, hoverStyle, wbackColor, false);
            }

            $(this).css({
                'text-decoration': hoverStyle.u ? 'underline' : 'none',
                color: hoverStyle.c,
            });

            if (!hoverStyle.u) {
                isLinkUnderline = false;
            }
        }
    }

    function leave() {
        var hoverStyle = $.parseJSON($(this).attr('stylehover'));
        var wbackColor = $.parseJSON($(this).attr('wbackColor_h'));

        if (hoverStyle == null && wbackColor == null) {
            return;
        }

        $(this).css('display', '');

        for (var j = 0; (leaveLen = this.childNodes.length), j < leaveLen; j++) {
            var child = this.childNodes[j];
            checkAndSetStyle(child, 3, hoverStyle, wbackColor, true);
        }
        if (!isLinkUnderline) {
            $(this).css({
                'text-decoration': 'none',
            });
            isLinkUnderline = true;
        }
        if ($(this).attr('astyle_h') == 1) {
            $(this).css({ color: '' + colorCache + '' });
            $(this).css({ 'text-decoration': '' + underlineCache + '' });
        } else if ($(this).attr('astyle_h') == 2) {
            $(this).css({ color: '' });
            $(this).css({ 'text-decoration': '' });
        }
        underlineCache = null;
        colorCache = null;
        textNodeParentCss = null;
    }

    function checkAndSetStyle(child, type = 0, hoverStyle, wbackColor, reset) {
        var childNodesLen = child.childNodes.length;
        if (child.nodeType == 1 && childNodesLen > 0) {
            //当是元素类型的时候
            for (var k = 0; k < childNodesLen; k++) {
                checkAndSetStyle(child.childNodes[k], type, hoverStyle, wbackColor, reset);
            }
        }
        if (child.nodeType == 3) {
            //找到文本节点
            if (reset) {
                child.parentNode.style.cssText = textNodeParentCss;
            } else {
                textNodeParentCss = child.parentNode.style.cssText;
                if (type == 1) {
                    $(child.parentNode).css({
                        //对文本节点的父节点设置hover样式
                        'font-size': hoverStyle.fs,
                        'font-weight': hoverStyle.b ? 'bold' : 'normal',
                        'font-family': hoverStyle.ff,
                        color: hoverStyle.c,
                    });
                } else if (type == 2) {
                    $(child.parentNode).css({
                        'background-color': wbackColor.bc,
                    });
                } else if (type == 3) {
                    $(child.parentNode).css({
                        'font-size': hoverStyle.fs,
                        'font-weight': hoverStyle.b ? 'bold' : 'normal',
                        'font-family': hoverStyle.ff,
                        color: hoverStyle.c,
                        'background-color': wbackColor.bc,
                    });
                }
            }
        }
    }
};
