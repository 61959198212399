var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    {
      staticClass: "product-specs",
      class: [`product-specs-style-${_vm.styleType}`],
    },
    [
      _vm._l(_setup.renderSpecs, function (spec, specIndex) {
        return _c("div", { key: spec.name, staticClass: "spec-container" }, [
          _c("div", { staticClass: "spec-name" }, [_vm._v(_vm._s(spec.name))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "spec-items" },
            _vm._l(spec.items, function (item) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.valid,
                      expression: "item.valid",
                    },
                  ],
                  key: item.id,
                  class: [
                    item.picture ? "spec-picture-item" : "spec-text-item",
                    item.active ? "spec-item-active" : "",
                  ],
                  on: {
                    click: function ($event) {
                      return _setup.select(item, spec, specIndex)
                    },
                  },
                },
                [
                  _c(
                    _setup.FaTooltip,
                    {
                      attrs: {
                        title:
                          item.picture && !_setup.isMobi ? item.text : null,
                      },
                    },
                    [
                      _c("div", { staticClass: "spec-item-wrapper" }, [
                        item.picture
                          ? _c(
                              "div",
                              { staticClass: "spec-picture-wrapper" },
                              [
                                _vm.canTriggerSlider
                                  ? _c(
                                      "div",
                                      { staticClass: "expand-icon-wrapper" },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "expand-picture-icon",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              viewBox: "0 0 20 20",
                                              width: "20",
                                              height: "20",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _setup.handleExpandImage(
                                                  spec.items,
                                                  item.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                fill: "none",
                                                stroke: "#fff",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                "stroke-width": "2",
                                                d: "M4 11L4 16 9 16",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                fill: "none",
                                                stroke: "#fff",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                "stroke-width": "2",
                                                d: "M16 9L16 4 11 4",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(_setup.ImageComponent, {
                                  directives: [
                                    {
                                      name: "lazyload2",
                                      rawName: "v-lazyload2",
                                      value: {
                                        src: item.picture,
                                      },
                                      expression:
                                        "{\n                                    src: item.picture,\n                                }",
                                    },
                                  ],
                                  staticClass: "spec-picture",
                                  attrs: {
                                    src: item.picture,
                                    webp: _setup.webpOptOpen,
                                    alt: item.text,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "spec-item-text",
                            class: { ellipsis: item.picture },
                          },
                          [_vm._v(_vm._s(item.text))]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ])
      }),
      _vm._v(" "),
      _c(_setup.ImageMainViewer, {
        attrs: {
          "show-mobi-viewer": _setup.showMobiViewer,
          "show-pc-viewer": _setup.showPCViewer,
          "z-index": _setup.zIndex,
          "initial-index": _setup.initialIndex,
          "url-list": _setup.urlList,
          "is-mobi": _setup.isMobi,
          "manage-mode": _setup.manageMode,
          "on-close": _setup.closeImageViewer,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }