import request from '@jz/request';

export function getProductGroupList(data) {
    return request
        .post('/rajax/productGroup_h.jsp?cmd=getWafNotCk_getList&fullName=true', { data })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getCommentInfo(data) {
    return request
        .post('/rajax/productModule_h.jsp?cmd=getWafNotCk_getProductComment', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function submitComment(data) {
    return request.post('/ajax/productComment_h.jsp?cmd=addWafCk_submitPC', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getProductListByPage(data) {
    return request
        .post('/rajax/productModule_h.jsp?cmd=getWafNotCk_getProductListByPage', { data })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getProductResultByPagination(params) {
    return request
        .get('/rajax/productModule_h.jsp?cmd=getWafNotCk_getProductResult', {
            params,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getPropNameByLib(data) {
    return request
        .post('/rajax/productModule_h.jsp?cmd=getWafNotCk_getScreenProduct', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function delProduct(data = {}) {
    return request.post('/ajax/product_h.jsp?cmd=delWafCk_del', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function setProductList(data = {}) {
    return request.post('/rajax/productModule_h.jsp?cmd=setWafCk_setProductList', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getProductNativePayUrl(data = {}) {
    return request.post('/ajax/product_h.jsp?cmd=getWafNotCk_getNativePayUrl', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getKnowProductOrderList4member(params = {}) {
    return request
        .get('/ajax/product_h.jsp?cmd=getWafNotCk_getKnowProductOrderList4member', { params })
        .then(({ data = {} }) => {
            return data;
        });
}

export function checkProductPayStatus(data = {}) {
    return request
        .post('/ajax/product_h.jsp?cmd=getWafNotCk_checkKnowProductOrderPayStatus', { data })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getKnowProductImageText(params = {}) {
    return request
        .get('/ajax/product_h.jsp?cmd=getWafNotCk_getKnowProductImageText', { params })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getKnowProductVideo(params = {}) {
    return request.get('/ajax/product_h.jsp?cmd=getWafNotCk_getKnowProductVideo', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function genKnowProductOrder(params = {}) {
    return request.get('/ajax/product_h.jsp?cmd=setWafCk_genKnowProductOrder', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function getKnowProductAudio(params = {}) {
    return request.get('/ajax/product_h.jsp?cmd=getWafNotCk_getKnowProductAudio', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function getKnowProductList(params = {}) {
    return request.get('/ajax/product_h.jsp?cmd=getWafNotCk_getKnowProductList', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function getPayProductNativePayUrl(params = {}) {
    return request.get('/api/guest/jzEcommerce/getCallPayParam', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function getPayProductNativePayUrlInMobi(params = {}) {
    return request.get('/api/guest/jzEcommerce/getCallPayParamWithoutPay', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function memberGetOrderList(params = {}) {
    return request.get('/api/guest/jzEcommerce/memberGetOrderList', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function checkProductCanBuy(params = {}) {
    return request.get('/api/guest/jzEcommerce/checkProductCanBuy', { params }).then(({ data = {} }) => {
        return data;
    });
}

/**
 *
 * @param {number} pdId
 * @param {number} amount
 * @param {{
 *  optionId: string,
 *  optionName: string[]
 * }} spec - 规格数据
 * @returns
 */
export function addToCart(pdId, amount = 1, spec = {}) {
    return request
        .post('/api/guest/jzEcommerce/addCart', {
            data: {
                pdId,
                amount,
                optionId: spec.optionId ?? '',
                optionName: JSON.stringify(spec.optionName ?? []),
            },
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function delProductForCart(delPdIdList, selectPdIdList, delPdOptionIdList, selectPdOptionIdList = []) {
    return request
        .post('/api/guest/jzEcommerce/delCart', {
            data: {
                delPdIdList: JSON.stringify(delPdIdList),
                selectPdIdList: JSON.stringify(selectPdIdList),
                delPdOptionIdList: JSON.stringify(delPdOptionIdList),
                selectPdOptionIdList: JSON.stringify(selectPdOptionIdList),
            },
        })
        .then(({ data = {} }) => {
            return data;
        })
        .catch((err) => {
            return { success: false, msg: err.msg || '系统错误' };
        });
}

export function delAllProductForCart() {
    return request
        .post('/api/guest/jzEcommerce/delAllCart')
        .then(({ data }) => {
            return data;
        })
        .catch((err) => {
            return { success: false, msg: err.msg || '系统错误' };
        });
}

export function setCart(pdId, amount, selectPdIdList, optionId, selectPdOptionIdList = []) {
    return request
        .post('/api/guest/jzEcommerce/setCart', {
            data: {
                pdId,
                amount,
                selectPdIdList: JSON.stringify(selectPdIdList),
                optionId: optionId,
                selectPdOptionIdList: JSON.stringify(selectPdOptionIdList),
            },
        })
        .then(({ data }) => {
            return data;
        })
        .catch((err) => {
            return { success: false, msg: err.msg || '系统错误' };
        });
}

/**
 *
 * @param {*} pdIdList
 * @param {*} selectPdOptionIdList
 * @returns
 */
export function calculateCartPrice(pdIdList, selectPdOptionIdList = []) {
    return request
        .get('/api/guest/jzEcommerce/calculateCartPrice', {
            params: {
                pdIdList: JSON.stringify(pdIdList),
                selectPdOptionIdList: JSON.stringify(selectPdOptionIdList),
            },
        })
        .then(({ data }) => {
            return data;
        })
        .catch((err) => {
            return { success: false, msg: err.msg || '系统错误' };
        });
}

export function calculateItemListPrice(itemList) {
    return request
        .get('/api/guest/jzEcommerce/calculateItemListPrice', {
            params: {
                itemList: JSON.stringify(itemList),
            },
        })
        .then(({ data }) => {
            return data;
        })
        .catch((err) => {
            return { success: false, msg: err.msg || '系统错误' };
        });
}

export function getShoppingInfo(selectPdOptionList = []) {
    return request
        .get('/api/guest/jzEcommerce/getOrderSettleItemList', {
            params: {
                selectPdOptionList: JSON.stringify(selectPdOptionList),
            },
        })
        .then(({ data }) => {
            return data;
        })
        .catch((err) => {
            return { success: false, msg: err.msg || '系统错误' };
        });
}
