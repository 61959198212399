import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState(['device']),
        isMobi() {
            return this.device === 'mobi';
        },
        pcBannerType: {
            get() {
                return this.bannerList.t;
            },
            set(v) {
                this.bannerList.t = v;
            },
        },
        mobiBannerType: {
            get() {
                if (this.bannerList.ismt) {
                    return this.bannerList.mt;
                } else {
                    if (this.bannerList.t === 0 || this.bannerList.t === 1) {
                        return 0;
                    } else {
                        return 2;
                    }
                }
            },
            set(v) {
                this.bannerList.mt = v;
            },
        },
        curBannerType: {
            get() {
                return this.isMobi ? this.mobiBannerType : this.pcBannerType;
            },
            set(v) {
                this[this.isMobi ? 'mobiBannerType' : 'pcBannerType'] = v;
            },
        },
    },
};
