import { PayProduct } from '@/services';
import { warningMessage } from '@/site/utils';
import { onMounted, ref } from 'vue';

export const useProductList = () => {
    const productList = ref([]);
    const total = ref(0);

    // 详情页跳过来，读取 count、specs
    const shoppingList = PayProduct.getShoppingList();
    if (Array.isArray(shoppingList) && shoppingList.length > 0) {
        onMounted(async () => {
            try {
                const { list, totalPrice } = await PayProduct.getShoppingInfo(shoppingList);
                productList.value = list;
                total.value = totalPrice;
            } catch (error) {
                warningMessage(error);
            }
        });
    }
    return { productList, totalPrice: total };
};
