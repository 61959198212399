/**
 *
 * 产品详情规格 mixin
 *
 * @example 通用产品详情、跨境产品详情复用
 * @dependency
 *  module - 产品详情模块 module 数据
 *
 */
export const productSpecMixin = {
    data() {
        return {
            /**
             * 选择的规格数据
             * 默认没有，所有价格是最小价格 ~ 最大价格
             */
            selectedSpecData: {
                price: 0,
            },
        };
    },
    computed: {
        productSpecs() {
            return this.module.showOptionList;
        },
        productSpecsConfigs() {
            return this.module.optionsAmountList;
        },
        hasSpecs() {
            return this.productSpecs.length > 0;
        },
        productSpecStyleType() {
            return this.isMobi ? 0 : this.module.prop0;
        },
    },
    created() {
        // 初始化价格
        if (this.module) {
            this.selectedSpecData.price =
                this.module.minPrice === this.module.maxPrice
                    ? this.module.minPrice
                    : [this.module.minPrice, this.module.maxPrice];
        }
    },
    methods: {
        selectSpecs(selectedSpecData) {
            this.selectedSpecData = selectedSpecData;
            // 计算价格
            const matchedConfig = this.productSpecsConfigs.find((config) => config.t2 === selectedSpecData.combination);
            if (!matchedConfig) {
                // 没有找到对应的配置，则显示最小价格 ~ 最大价格
                if (this.module && this.module.minPrice === this.module.maxPrice) {
                    this.selectedSpecData.price = this.module.minPrice;
                } else {
                    this.selectedSpecData.price = [this.module.minPrice, this.module.maxPrice];
                }
            } else {
                this.selectedSpecData.price = matchedConfig.oPrice;
            }
        },
        /**
         * 依赖 ProductSpecRef
         * @returns
         */
        validateSpecs() {
            if (this.hasSpecs === false) return true;
            if (!this.$refs.ProductSpecRef) return false;

            const specValidateResult = this.$refs.ProductSpecRef.validate();
            if (specValidateResult.success == false) {
                this.$modal.confirm({
                    title: specValidateResult.data.message,
                    confirmButtonText: this.LS.confirm,
                    cancelButtonText: this.LS.cancel,
                });
                return false;
            }
            return true;
        },
    },
};
