var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpenCopyright ||
    _vm.isOpenSupport ||
    _vm.isOpenAdmin ||
    _vm.isOpenIpv6Identy ||
    _vm.isOpenPoliceRecord ||
    _vm.isOpenRecordation
    ? _c("div", { staticClass: "s_footer" }, [
        _vm.isOpenPoliceRecord
          ? _c("p", { staticClass: "s_footer_police" }, [
              _c("span", { staticClass: "police_record_icon" }),
              _vm._v(" "),
              _c("a", {
                attrs: {
                  href: _vm.recordationSettingHtml,
                  target: _vm.recordationSettingHtmlIsEmpty ? "" : "_blank",
                },
                domProps: {
                  textContent: _vm._s(_vm.mpsRecordationSetting.code),
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isOpenCopyright
          ? _c("p", { staticClass: "s_footer_copyright" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.copyright.content ||
                      _vm.sections.copyrightContentDefault
                  ) +
                  "\n    "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "s_footer_support_container" },
          [
            _vm.isOpenSupport
              ? _c("span", {
                  staticClass: "s_footer_support",
                  domProps: { innerHTML: _vm._s(_vm.sections.supportText) },
                })
              : _vm._e(),
            _vm.isOpenAdmin && !_vm.isOemDemo
              ? _c(
                  "a",
                  {
                    staticClass:
                      "s_footer_anchor jz_hover jz_theme_font_hover_color",
                    attrs: { href: _vm.sections.adminHref, target: "_blank" },
                  },
                  [_vm._v(_vm._s(_vm.sections.footerLogin))]
                )
              : _vm._e(),
            _vm.openSitemapPage && _vm.sitemapPageUrl
              ? _c(
                  "a",
                  {
                    staticClass:
                      "s_footer_anchor jz_hover jz_theme_font_hover_color",
                    attrs: { href: _vm.sitemapPageUrl, target: "_blank" },
                  },
                  [_vm._v("sitemap")]
                )
              : _vm._e(),
            _vm.isOpenRecordation && _vm.hasBeiAn && !_vm.beianCodeIsEmpty
              ? [
                  _c("a", {
                    staticClass:
                      "s_footer_anchor jz_hover jz_theme_font_hover_color",
                    attrs: {
                      href: "https://beian.miit.gov.cn/",
                      target: "_blank",
                    },
                    domProps: { textContent: _vm._s(_vm.beianCode) },
                  }),
                  _vm._v(" "),
                  _vm.isOpenIpv6Identy
                    ? _c("span", { staticClass: "s_footer_spance" }, [
                        _vm._v("|"),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.isOpenIpv6Identy
              ? _c(
                  "span",
                  {
                    staticClass: "footerIpv6",
                    staticStyle: {
                      position: "relative",
                      "padding-right": "40px",
                    },
                    attrs: { id: "footerIpv6" },
                  },
                  [
                    _vm._v(_vm._s(_vm.isCN ? "本站支持" : "") + " "),
                    _c(
                      "svg",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "0px",
                          width: "40px",
                          height: "19px",
                        },
                      },
                      [_c("use", { attrs: { "xlink:href": "#icon_ipv6" } })]
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }