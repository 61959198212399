import { logJZFdp } from '@/site/shared/log/index.js';
export function initModuleTransformTrack(moduleId, moduleStyle, tts, isLinkToForm = false) {
    const $module = $(`#module${moduleId}`);
    if (tts == null) return;
    const { t, al = [], e } = tts;
    // 符合版本条件 && 开关开启 && （含有付费域名 || 内部账号 || 模板账号)
    if (
        window._store.state?.realNameAuth?.allowShowMessage &&
        window._store.state?.transformatinTrackingGlobalModel?.openTransformationTracking
    ) {
        if (moduleStyle == 8) {
            // 按钮模块
            let $btn = $module.find('a');
            t == 0
                ? initTransformTrackAttr($btn, al, '按钮模块')
                : initTransformTrackEvent($btn, moduleId, e, '按钮模块');
        } else if (moduleStyle == 21) {
            // 表单模块
            let $btn = $module.find('.submit_btn_container');
            if ($btn.length == 0) {
                $btn = $module.find('.form_submit');
            }
            t == 0
                ? initTransformTrackAttr($btn, al, '表单提交按钮模块')
                : initTransformTrackEvent($btn, moduleId, e, '表单提交按钮模块');
        } else if (isLinkToForm) {
            let $btn = $(`.p_popup_container .form_submit`);
            t == 0
                ? initTransformTrackAttr($btn, al, '表单提交按钮模块')
                : initTransformTrackEvent($btn, moduleId, e, '表单提交按钮模块');
        }
    }
}

function initTransformTrackAttr($el, al = [], moduleName) {
    al.forEach((item) => {
        $el.attr(item.k, item.v);
    });
    if (al.length) {
        $el.on('click.transformTrack', function () {
            transformTrackFdpLog(moduleName);
        });
    }
}

function initTransformTrackEvent($el, moduleId, eventStr, moduleName) {
    if (eventStr == null) {
        console.warn(`${moduleId} 的事件为空`);
        return;
    }
    let matchList = eventStr.match(/(function)([ \t])([^\\(]+)/);
    if (matchList == null || (matchList != null && (matchList[3] == null || matchList[3] == ''))) {
        console.log(`模块${moduleId}的事件名不存在, ${eventStr}`);
    } else {
        try {
            let fnName = matchList[3];
            // 挂载script
            const scriptEl = document.createElement('script');
            scriptEl.type = 'text/javascript';
            scriptEl.innerHTML = eventStr;
            scriptEl.id = `module${moduleId}_track`;
            $('body').append(scriptEl);
            // 事件
            $el.on('click', function () {
                try {
                    transformTrackFdpLog(moduleName);
                    window[fnName] && window[fnName]();
                } catch (err) {
                    console.log(err);
                }
            });
        } catch (err) {
            console.log(err);
        }
    }
}

function transformTrackFdpLog(moduleName) {
    logJZFdp('jz_transformation_tracking_trigger', {
        jz_content_terminal: '自适应',
        jz_free_text1: moduleName,
    });
}
