export function appendFacemessenger(pageId) {
    if (pageId == null || pageId == '') {
        console.warn('Facebook Messenger PageID不能为空，请设置');
        return;
    }

    removeFacemessenger();

    let messengerUrl = `//www.messenger.com/t/${pageId}`;
    let messengerImg = window._store.state.resImageRoot + '/image/rimage/fromSite/message-us-blue.png';

    let html = `
      <div id="facebook-messenger" class="message_us">
        <a href="${messengerUrl}" target="_blank" title="Message Us">
          <img src="${messengerImg}" alt="Message Us" class="img_loading">
        </a>
      </div>`;
    $('body #jz_web').append(html);
}

export function removeFacemessenger() {
    let facebookEl = $('#facebook-messenger');
    if (facebookEl.length) {
        facebookEl.remove();
    }
}
