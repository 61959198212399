import Project from '@/components/Project.vue';
import JzWebRow from '@/components/grid/row/jzWebRow.vue';
import JzWebRows from '@/components/grid/row/jzWebRows.vue';
const components = [Project, JzWebRow, JzWebRows];
import { installModal } from '@jz/biz-shared';

export function registerGlobalComponents(Vue) {
    if (typeof window !== 'undefined') {
        installModal(Vue);
    }
    components.forEach((component) => Vue.component(component.name, component));
}
