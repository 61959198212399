import { GLOBAL_FONT_FAMILY_NAME_CSS_VALUE } from '@/store/modules/manage/modules/globalFont';
import { Comm } from '../../../utils';
const { getComputedTextColor, extend } = Comm;
function genCommFontStyleForText(fontStyle, style = {}) {
    if (fontStyle.y === 2) {
        style['font-size'] = `${fontStyle.s}px`;
        style['font-family'] = fontStyle.f;
        if (fontStyle.ugf === 1) {
            style['font-family'] = GLOBAL_FONT_FAMILY_NAME_CSS_VALUE;
        }
        style['font-style'] = fontStyle.i == 1 ? 'italic' : 'normal';
        style['font-weight'] = fontStyle.w == 1 ? 'bold' : 'normal';
        style['text-decoration'] = fontStyle.d == 1 ? 'underline' : 'none';
        extend(style, getComputedTextColor(fontStyle.c));
    }
    return style;
}

export { genCommFontStyleForText };
