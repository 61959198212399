var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.fileItem.isImage
    ? _c("div", {
        staticClass: "m_custom_result_img",
        style: _vm.imgStyle,
        attrs: {
          "data-path": _vm.fileItem.path,
          "data-name": _vm.fileItem.fileName,
          "data-download": _vm.fileItem.downloadPath,
          "data-id": _vm.fileItem.id,
        },
        on: { click: _vm.emitImgClick },
      })
    : _vm.fileItem.isPdf
    ? _c(
        "a",
        {
          staticStyle: { color: "inherit", "font-size": "inherit" },
          attrs: {
            hidefocus: "true",
            rel: "nofollow",
            target: "_blank",
            href: _vm.fileItem.path,
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.fileItem.fileName) + "\n")]
      )
    : !_vm.fileItem.isImage && !_vm.fileItem.isPdf
    ? _c(
        "a",
        {
          staticStyle: { color: "inherit", "font-size": "inherit" },
          attrs: {
            hidefocus: "true",
            rel: "nofollow",
            target: "_blank",
            href: _vm.fileItem.downloadPath,
          },
          on: { click: _vm.fileDownload },
        },
        [_vm._v("\n    " + _vm._s(_vm.fileItem.fileName) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }