var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 24 24",
      },
      on: { click: _vm.clickHandler },
    },
    [
      _c("defs", [
        _c("clipPath", { attrs: { id: "a" } }, [
          _c("rect", { attrs: { width: "24", height: "24", fill: "none" } }),
        ]),
      ]),
      _vm._v(" "),
      _c("rect", { attrs: { width: "24", height: "24", fill: "#fff" } }),
      _vm._v(" "),
      _c("line", {
        attrs: {
          x1: "5",
          y1: "8",
          x2: "19",
          y2: "8",
          fill: "none",
          stroke: "currentColor",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          "stroke-width": "2",
        },
      }),
      _vm._v(" "),
      _c("g", { attrs: { "clip-path": "url(#a)" } }, [
        _c("path", {
          attrs: {
            d: "M7,9v7a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V9",
            fill: "none",
            stroke: "currentColor",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M16,7V6a1,1,0,0,0-1-1H9A1,1,0,0,0,8,6V7",
            fill: "none",
            stroke: "currentColor",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            x1: "10",
            y1: "12",
            x2: "10",
            y2: "14",
            fill: "none",
            stroke: "currentColor",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
        _vm._v(" "),
        _c("line", {
          attrs: {
            x1: "14",
            y1: "12",
            x2: "14",
            y2: "14",
            fill: "none",
            stroke: "currentColor",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }