import Timeline from './module/Timeline';
import FriendLink from './module/FriendLink';
import NewsResult from './module/NewsResult';
import ProductDetail from './module/ProductDetail';
import Tabpack from './module/Tabpack';
import IconCombination from './module/IconCombination';
import CateGoryNav from './module/CateGoryNav';
import ProductResult from './module/ProductResult';
import NewsDetail from './module/NewsDetail';
import Accordion from './module/Accordion';
import RollingNotice from './module/rollingNotice';
import onlineForm from './module/onlineForm';
import Flv from './module/Flv';

export const componentMap = {
    5: NewsResult,
    37: Timeline,
    38: FriendLink,
    9: ProductDetail,
    32: Tabpack,
    33: IconCombination,
    14: CateGoryNav,
    18: CateGoryNav,
    39: CateGoryNav,
    10: ProductResult,
    4: NewsDetail,
    41: Accordion,
    42: RollingNotice,
    21: onlineForm,
    20: Flv,
};

export const NEED_SSR_MODULE_STYLES = Object.keys(componentMap).map((key) => Number(key));
