class FontIndexedDb {
    constructor() {
        this.DB_NAME = 'jzSiteFont';
        this.DB_VERSION = 1; // 新建一个表的时候，要把版本号改了才会更新
        this.FONT_FILE_SOTRE_NAME = 'fontFile';
        this.STORE_LIST = [
            {
                name: this.FONT_FILE_SOTRE_NAME,
                options: {
                    keyPath: 'fontId',
                    autoIncrement: false,
                },
                indexs: [
                    {
                        key: 'fontName',
                        unique: true,
                    },
                    {
                        key: 'file',
                        unique: true,
                    },
                ],
            },
        ];
        this.db = null;
        this.fontList = [];
        this._opened = false;
        this._getFontListDone = false;
        this.getFontListPromise = this._openDb();
    }

    // 是否支持indexedDB
    static isSupport() {
        return typeof window.indexedDB !== 'undefined';
    }

    isSupport() {
        return FontIndexedDb.isSupport();
    }

    getObjectStore(storeName, mode) {
        const tx = this.db.transaction(storeName, mode);
        return tx.objectStore(storeName);
    }

    checkOpened() {
        return this._opened;
    }

    checkGetFontListDone() {
        return this._getFontListDone;
    }

    _openDb() {
        return new Promise((resolve, reject) => {
            if (!this.isSupport()) {
                reject('该浏览器不支持indexedDb');
                return;
            }
            const request = window.indexedDB.open(this.DB_NAME, this.DB_VERSION);

            request.onsuccess = (event) => {
                this.db = event.target.result;
                this._opened = true;
                this.getAllFont().then(() => {
                    this._getFontListDone = true;
                    resolve(this.db);
                });
            };

            request.onerror = (err) => {
                console.error(err);
                this.db = null;
            };

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                this.STORE_LIST.forEach((storeData) => {
                    const { name, options, indexs } = storeData;
                    if (!db.objectStoreNames.contains(name)) {
                        const store = db.createObjectStore(name, options);
                        indexs.forEach((item) => {
                            store.createIndex(item.key, item.key, {
                                unique: item.unique || true,
                            });
                        });
                    }
                });
            };
        });
    }

    addFont(fontId, fontName, arrayBuffer) {
        return new Promise((resolve, reject) => {
            if (!this.checkOpened()) {
                reject('请先开启数据库');
                return;
            }
            if (!fontId || !fontName || !arrayBuffer) {
                reject('参数错误');
                return;
            }
            const fontData = {
                fontId,
                fontName,
                arrayBuffer,
                version: this.DB_VERSION,
            };
            const store = this.getObjectStore(this.FONT_FILE_SOTRE_NAME, 'readwrite');
            const request = store.add(fontData);
            request.onsuccess = () => {
                this.fontList.push(fontData);
                resolve();
            };

            request.onerror = (err) => {
                reject(`addFont err => , ${JSON.stringify(err)}`);
            };
        });
    }

    /**
     * 这里初始化一次拿全部字体，可能会比较耗时和卡顿
     */
    getAllFont() {
        return new Promise((resolve, reject) => {
            if (!this.checkOpened()) {
                reject('请先开启数据库');
                return;
            }
            const store = this.getObjectStore(this.FONT_FILE_SOTRE_NAME, 'readwrite');
            // console.time('getAllFont');
            const request = store.openCursor();
            const fontList = [];

            request.onsuccess = (event) => {
                const cursor = event.target.result;
                if (cursor) {
                    fontList.push(cursor.value);
                    cursor.continue();
                } else {
                    this.fontList = fontList.slice(0);
                    // console.timeEnd('getAllFont');
                    resolve(fontList);
                }
            };

            request.onerror = (err) => {
                reject(`getAllFont err => , ${err}`);
            };
        });
    }
}

if (typeof window !== 'undefined') {
    window.FontIndexedDb = FontIndexedDb;
}

export { FontIndexedDb };
