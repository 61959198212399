
import Counter from '../../common/Counter/index.vue';
import { mapGetters, mapState } from 'vuex';
import { setUrlArgToCookieAndJump } from '@/site/shared/cookie/index.js';
import Deleter from './cart_delete.vue';

export default {
    components: {
        Counter,
        Deleter,
    },
    props: {
        list: {
            type: Array,
            required: true,
        },
        canModify: {
            type: Boolean,
            default: true,
        },
        unitInfo: {
            required: true,
            type: Object,
        },
        del: {
            type: Boolean,
            default: false,
        },
        check: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['isMobi']),
        ...mapState(['LS']),
    },
    render() {
        const LS = this.LS;

        const addUnit = (price) => {
            const { siteCurrencyRight, currencySymbol } = this.unitInfo || {};
            if (siteCurrencyRight && currencySymbol != '￥') {
                return `${price}${currencySymbol}`;
            }
            return `${currencySymbol}${price}`;
        };

        const goProductDetail = (url, pdReqArgs, pdInvalid) => {
            if (pdInvalid) return;
            if (url != '' && url != null) {
                setUrlArgToCookieAndJump(url, pdReqArgs, { blank: true });
            }
        };
        const getImgNode = (item) => (
            <img
                class="shopping_item_img"
                src={item.pdImgPath}
                onClick={() => goProductDetail(item.jumpUrl, item.pdReqArgs, item.pdInvalid)}
            />
        );
        const getNameNode = (item) => {
            let { pdInvalid, optionInvalid } = item;
            const classNames = ['shopping_item_text', optionInvalid && 'option-invalid'];
            return (
                <div class={classNames} onClick={() => goProductDetail(item.jumpUrl, item.pdReqArgs, item.pdInvalid)}>
                    {pdInvalid ? <div>{item.pdName}</div> : item.pdName}
                    {this.renderSpecs(item.optionName, item.optionInvalid)}
                    {pdInvalid ? <div class="delisted">{LS.notAdded}</div> : null}
                </div>
            );
        };
        const getPriceNode = (item) => <div class="shopping_item_price">{addUnit(Number(item.price).toFixed(2))}</div>;

        const countUpdate = (item) => {
            this.$emit('count', item);
        };

        const getCountNode = (item) => (
            <div class="shopping_item_count">
                {this.canModify ? (
                    <Counter v-model={item.amount} size="small" onChange={() => countUpdate(item)} />
                ) : (
                    `x${item.amount}`
                )}
            </div>
        );
        const checkProduct = (item) => {
            if (item.pdInvalid === true || item.optionInvalid === true) return;
            item.checked = !item.checked;
            this.$emit('check', item);
        };

        const getCheckNode = (item) => {
            let checkClass = ['checkbox_item'];
            let { checked, pdInvalid, optionInvalid } = item;
            if (pdInvalid || optionInvalid) {
                checkClass.push('checkbox_disable');
            } else if (checked) {
                checkClass.push('checkbox_active');
            }
            return (
                <div class="shopping_item_check" onClick={() => checkProduct(item)}>
                    <span class={checkClass}>
                        <span class="checkbox_inner jz_theme_bg_color faisco-icons-S000335"></span>
                    </span>
                </div>
            );
        };

        const delProduct = async (item) => {
            this.$emit('del', item);
        };

        const getDelNodeForPc = (item) => (
            <div class="shopping_item_del" onClick={() => delProduct(item)}>
                {LS.mall_delete}
            </div>
        );

        const getDelNodeForMobi = (item) => <Deleter class="shopping_item_del_icon" onClick={() => delProduct(item)} />;

        const getItemNodeInPc = (item) => {
            let wrapClass = ['shopping_item'];
            let { pdInvalid, optionInvalid } = item;
            if (pdInvalid) {
                wrapClass.push('invalid');
            }
            const invalidProduct = pdInvalid || optionInvalid;
            return (
                <div class={wrapClass} key={`${item.pdId}-${item.optionId ?? 0}`}>
                    <div class="shopping_item_name">
                        {this.check ? getCheckNode(item) : null}
                        {getImgNode(item)}
                        {getNameNode(item)}
                    </div>
                    {invalidProduct ? null : getPriceNode(item)}
                    {invalidProduct ? null : getCountNode(item)}
                    {invalidProduct ? null : (
                        <div class="shopping_item_subtotal">{addUnit((item.price * item.amount).toFixed(2))}</div>
                    )}
                    {this.del ? getDelNodeForPc(item) : null}
                </div>
            );
        };

        const getItemNodeInMobi = (item) => {
            let wrapClass = ['shopping_item_mobi'];
            let { pdInvalid, optionInvalid } = item;
            if (pdInvalid) {
                wrapClass.push('invalid');
            }
            const invalidProduct = pdInvalid || optionInvalid;
            return (
                <div class={wrapClass} key={`${item.pdId}-${item.optionId ?? 0}`}>
                    <div class="shopping_item_left">
                        {this.check ? getCheckNode(item) : null}
                        {getImgNode(item)}
                    </div>
                    <div class="shopping_item_right">
                        {getNameNode(item)}
                        <div class="shopping_item_right_bottom">
                            {invalidProduct ? null : getPriceNode(item)}
                            {invalidProduct ? null : getCountNode(item)}
                        </div>
                        {this.del ? getDelNodeForMobi(item) : null}
                    </div>
                </div>
            );
        };
        const wrapClass = [
            'shopping_list_wrap',
            !this.canModify ? 'shopping_list_wrap--notModify' : '',
            this.del && this.isMobi ? 'wrap_del' : '',
        ];
        return (
            <div class={wrapClass}>
                {!this.isMobi && (
                    <div class="shopping_header">
                        {this.check ? <div class="shopping_header_check"></div> : null}
                        <div class="shopping_header_name">{LS.orderListItemPdName}</div>
                        <div class="shopping_header_price">{LS.orderListItemPdPrice}</div>
                        <div class="shopping_header_count">{LS.orderListItemPdAmount}</div>
                        <div class="shopping_header_subtotal">{LS.orderDetailItemTotal}</div>
                        {this.del ? <div class="shopping_header_opeartion">{LS.orderListItemOpt}</div> : null}
                    </div>
                )}
                <div class="shopping_body">
                    {this.list.map((item) => (this.isMobi ? getItemNodeInMobi(item) : getItemNodeInPc(item)))}
                </div>
            </div>
        );
    },
    methods: {
        /**
         *
         * @param specs - 规格值
         * @param invalid - 是否无效
         */
        renderSpecs(specs, invalid) {
            specs = typeof specs === 'string' ? JSON.parse(specs) : specs;
            specs = specs ?? [];

            const lastIndex = specs.length - 1;
            const tips = this.LS.invalid_product_spec;
            return (
                <div class="specs">
                    {!invalid ? (
                        specs.map((spec, index) => (
                            <span class="spec">
                                {spec}
                                {index < lastIndex ? <span class="spec-sep">, </span> : null}
                            </span>
                        ))
                    ) : (
                        <div class="spec-invalid-tips">{tips}</div>
                    )}
                </div>
            );
        },
    },
};
