export class PayKnowProduct {
    static isPayKnowProduct(productInfo) {
        return productInfo.productType === 2;
    }

    static getProductPayKnowType(productInfo) {
        return productInfo.other.pkp.payContentType;
    }

    static getProductPayKnowTypeText(productInfo) {
        return PayKnowProduct.productPayKnowTypesText[PayKnowProduct.getProductPayKnowType(productInfo)];
    }

    static async showTrialEndBuyPopup({ onBuy, tips = '', title = '' }) {
        const popupId = `pay-know-trial-end-buy-popup`;
        if ($(`#${popupId}`).length > 0) {
            return;
        }
        $('body').append(`<div id="${popupId}"></div>`);
        const PayKnowTrialEndBuyPopup = await import(
            /* webpackChunkName: "pay-know-trial-end-buy-popup" */ './PayKnowTrialEndBuyPopup.vue'
        );
        const close = () => {
            vm.$destroy();
            $(vm.$el).remove();
        };
        const vm = new Vue({
            render: (h) =>
                h(PayKnowTrialEndBuyPopup.default, {
                    props: {
                        tips,
                        title,
                    },
                    on: {
                        close: () => {
                            close();
                        },
                        buy: () => {
                            onBuy?.();
                        },
                    },
                }),
        }).$mount(`#${popupId}`);
        return { closePopup: close };
    }

    static getPayTipsContentHtml(resRoot) {
        return `
            <div style="text-align: center; font-size: 16px; color: #333; margin-top: 10px;">
                <img src="${resRoot}/image/v2/pay.png?v=1">
                <p>购买后查看全部内容</p>
            </div>
        `;
    }

    static async openColumnProductDirectory({ payColumnProductIdList, productColUrl, isBought, productColId }) {
        const popupId = `column-product-directory-popup`;
        if ($(`#${popupId}`).length > 0) {
            return;
        }
        const $container = window._store.getters.isMobi ? $('#jzPreviewContent') : $('body');
        $container.append(`<div id="${popupId}"></div>`);
        const PayKnowTrialEndBuyPopup = await import(
            /* webpackChunkName: "column-product-directory-popup" */ './ColumnProductDirectoryPopup.vue'
        );
        const close = () => {
            vm.$destroy();
            $(vm.$el).remove();
        };
        const vm = new Vue({
            store: window._store,
            render: (h) =>
                h(PayKnowTrialEndBuyPopup.default, {
                    props: {
                        payColumnProductIdList,
                        productColUrl,
                        isBought,
                        productColId,
                    },
                    on: {
                        close: () => {
                            close();
                        },
                    },
                }),
        }).$mount(`#${popupId}`);
        return { closePopup: close };
    }
}

PayKnowProduct.productPayKnowTypes = {
    VIDEO: 0,
    IMAGE_TEXT: 1,
    COLUMN: 2,
    AUDIO: 3,
};

PayKnowProduct.productPayKnowTypesText = {
    [PayKnowProduct.productPayKnowTypes.VIDEO]: '视频',
    [PayKnowProduct.productPayKnowTypes.IMAGE_TEXT]: '图文',
    [PayKnowProduct.productPayKnowTypes.COLUMN]: '专栏',
    [PayKnowProduct.productPayKnowTypes.AUDIO]: '音频',
};
