import { PayProduct } from '@/services';
import { warningMessage } from '@/site/utils';
import { computed, ref } from 'vue';
import { useMapState } from '@jz/utils';

const initCartList = (cartList = []) => {
    return cartList.map((item) => ({
        ...item,
        pdImgPath: item.pdImgUrl,
        uniqueId: item.pdId + '-' + item.optionId ?? '',
        checked: !item.pdInvalid && !item.optionInvalid,
    }));
};

/**
 * @typedef {Object} ShoppingCart
 * @member {Array} cartList - 购物车数据
 * @member {number} initialPrice - 初始价格
 * @member {string} checkoutUrl - 结算地址
 */
export const useShoppingCart = (
    shoppingCart = {
        cartList: [],
        initialPrice: 0,
        checkoutUrl: '',
    }
) => {
    const { LS } = useMapState(['LS', 'device', 'memberLogin', 'curLanCode']);

    const { cartList, initialPrice, checkoutUrl } = shoppingCart;

    const initialProducts = initCartList(cartList);
    const productList = ref(initialProducts);
    const price = ref(initialPrice);

    const priceText = computed(() => {
        return Number(price.value).toFixed(2);
    });

    const selectedProductList = computed(() => {
        return productList.value.filter((item) => item.checked === true);
    });

    // 构造传输给后台的数据
    /**
     * 选中的产品列表
     * {
     *  pdId: number,
     *  optionId: string
     * } []
     */
    const selectedProductOptionList = computed(() => {
        return selectedProductList.value.map((item) => ({
            pdId: item.pdId,
            optionId: item.optionId,
        }));
    });

    /**
     * 选中的产品列表Ids
     */
    const selectedProductIds = computed(() => {
        return selectedProductList.value.map((item) => item.pdId);
    });

    // 选中按钮
    const selectedAll = ref(true);
    const selectedAllClass = computed(() => {
        return selectedAll.value ? ['checkbox_active'] : [''];
    });

    const updateProductList = (data) => {
        productList.value.forEach((product) => {
            let item = data.find((i) => {
                return i.pdId == product.pdId && i.optionId == product.optionId;
            });
            if (item != null) {
                Object.assign(product, item);
                product.uniqueId = product.pdId + '-' + product.optionId;
            }
        });
    };

    // 全选操作
    const selectAll = async () => {
        selectedAll.value = !selectedAll.value;

        productList.value.forEach((item) => {
            if (!item.pdInvalid && !item.optionInValid) {
                item.checked = selectedAll.value;
            }
        });
        let result = await PayProduct.calculateCartPrice(selectedProductIds.value, selectedProductOptionList.value);
        if (result.success) {
            updateProductList(result.cartList);
            price.value = result.totalPrice;
        }
    };

    // 勾选产品
    const checkProduct = async () => {
        selectedAll.value = productList.value.every((item) => item.checked);
        let result = await PayProduct.calculateCartPrice(selectedProductIds.value, selectedProductOptionList.value);
        if (result.success) {
            updateProductList(result.cartList);
            price.value = result.totalPrice;
        }
    };

    // 移除产品
    const removeProduct = async (product) => {
        product.checked = false;

        let result = await PayProduct.delProductForCart(
            [product.pdId],
            selectedProductIds.value,
            [{ pdId: product.pdId, optionId: product.optionId }],
            selectedProductOptionList.value
        );
        if (result.success) {
            productList.value = productList.value.filter((item) => {
                return item.uniqueId !== product.uniqueId;
            });
            updateProductList(result.cartList);
            price.value = result.totalPrice;
        }
    };

    // 移除所有产品
    const removeSelectedProducts = async () => {
        if (selectedAll.value === true) {
            let result = await PayProduct.delAllCartProduct();
            if (result.success) {
                productList.value = [];
                price.value = result.totalPrice;
            }
        } else {
            const unSelectedProducts = productList.value.filter((item) => {
                return item.checked && selectedProductList.value.find((product) => product.uniqueId !== item.uniqueId);
            });
            let result = await PayProduct.delProductForCart(
                selectedProductIds.value,
                unSelectedProducts.map((item) => item.pdId),
                selectedProductOptionList.value,
                unSelectedProducts.map((item) => ({ pdId: item.pdId, optionId: item.optionId }))
            );
            if (result.success) {
                productList.value = unSelectedProducts;
                updateProductList(result.cartList);
                price.value = result.totalPrice;
            }
        }
    };

    const onChangeProductAmount = async (product) => {
        if (product.amount == 0) {
            return;
        }
        let result = await PayProduct.setCart(
            product.pdId,
            product.amount,
            selectedProductIds.value,
            product.optionId,
            selectedProductOptionList.value
        );
        if (result.success) {
            updateProductList(result.cartList);
            price.value = result.totalPrice;
        }
    };

    /**
     * 结算
     * @returns
     */
    const checkout = () => {
        let validSelectedProducts = selectedProductList.value.filter((item) => {
            return item.pdInvalid === false && item.optionInvalid === false;
        });
        if (validSelectedProducts.length == 0) {
            warningMessage(LS.value.js_selectProductCart, true);
            return;
        }

        PayProduct.setShoppingList(
            validSelectedProducts.map((product) => {
                return {
                    pdId: product.pdId,
                    optionId: product.optionId,
                    optionName: product.optionName,
                    amount: product.amount,
                };
            })
        );

        const idList = validSelectedProducts.map((item) => item.pdId);
        window.location.href = `${checkoutUrl}&selectPdIdList=${encodeURIComponent(JSON.stringify(idList))}`;
    };

    return {
        productList,
        selectedProductList,
        selectedProductOptionList,
        selectedProductIds,
        selectedAll,
        selectedAllClass,
        price,
        priceText,

        selectAll,
        checkProduct,
        removeProduct,
        removeSelectedProducts,
        onChangeProductAmount,
        checkout,
    };
};
