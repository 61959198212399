import { useMapGetters, useMapState } from '@jz/utils';
import { ref } from 'vue';

export const useImageViewer = (
    config = {
        urlList: [],
        initialIndex: 0,
        zIndex: 9999,
        showPCViewer: false,
        showMobiViewer: false,
    }
) => {
    const urlListRef = ref(config.urlList);
    const initialIndexRef = ref(config.initialIndex);
    const zIndexRef = ref(config.zIndex);
    const showPCViewerRef = ref(config.showPCViewer);
    const showMobiViewerRef = ref(config.showMobiViewer);

    const { isMobi } = useMapGetters(['isMobi']);
    const { manageMode } = useMapState(['manageMode']);

    const showImageViewer = (urlList, index) => {
        urlListRef.value = urlList;
        initialIndexRef.value = index;
        showPCViewerRef.value = !isMobi.value;
        showMobiViewerRef.value = isMobi.value;
    };

    const closeImageViewer = () => {
        showPCViewerRef.value = false;
        showMobiViewerRef.value = false;
    };

    return {
        urlList: urlListRef,
        initialIndex: initialIndexRef,
        zIndex: zIndexRef,
        showPCViewer: showPCViewerRef,
        showMobiViewer: showMobiViewerRef,
        isMobi: isMobi,
        manageMode: manageMode,
        showImageViewer,
        closeImageViewer,
    };
};
