import { memberSetOrder } from '@/api/order';
import { successMessage, warningMessage } from '@/site/utils';
import { checkProductCanBuy, getShoppingInfo } from '@/api/product';
import { isBrowserEnv } from '@jz/utils';

import {
    addToCart,
    delProductForCart,
    setCart,
    calculateCartPrice,
    calculateItemListPrice,
    delAllProductForCart,
} from '@/api/product';
import { createDialog } from '@/site/shared/dialog/index.js';

const STORAGE_COUNT_KEY = 'productCount';
const STORAGE_SHOPPING_LIST_KEY = 'shopping_list';

const MAX_LIMIT = 99999;
const MIN_LIMIT = 1;

export class PayProduct {
    static isPayProduct(productInfo, openECommerce = true) {
        return productInfo?.productType === 5 && openECommerce;
    }

    static getPayProductStatus(status, LS = isBrowserEnv() ? window.LS : {}) {
        return (
            {
                [PayProduct.payProductStatus.WAIT_PAY]: LS.jzOrderFinSettle,
                [PayProduct.payProductStatus.WAIT_SEND]: LS.jzOrderFinPay,
                [PayProduct.payProductStatus.SENT]: LS.mobi_toBeReceived,
                [PayProduct.payProductStatus.FINISHED]: LS.jzOrderFinConfirm,
                [PayProduct.payProductStatus.CLOSED]: LS.jzOrderFinCancel,
            }[status] || '-'
        );
    }

    static getTotalPrice(item) {
        return (item.amount * item.price).toFixed(2);
    }

    static cancelOrder(orderId) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$modal.confirm({
                title: `${window.LS.orderRealCancelMsg}`,
                async onConfirm() {
                    const data = await memberSetOrder({
                        orderId,
                        cancelOrder: true,
                    });

                    if (data.success) {
                        successMessage(window.LS.orderCancelSuccess, true);
                        resolve();
                    } else {
                        warningMessage(window.LS.orderCancelFail, true);
                        console.log('cancelOrder error', data);
                        reject();
                    }
                },
            });
        });
    }

    static confirmReceipt(orderId) {
        return new Promise((resolve, reject) => {
            memberSetOrder({
                orderId,
                confirmOrder: true,
            }).then((data) => {
                if (data.success) {
                    successMessage(window.LS.orderSureSuccess, true);
                    window.bizShared.context.fdpLog('jz_receive', {
                        jz_content_terminal: window.bizShared.context.jzContentTerminal,
                        jz_version: window.bizShared.context.jzVersion,
                    });
                    resolve();
                } else {
                    warningMessage(window.LS.orderSureFail, true);
                    console.log('confirmReceipt error', data);
                    reject();
                }
            });
        });
    }

    static setStoreInfo(productId, count, specConfig = { optionId: '', optionName: [] }) {
        window.localStorage.setItem(
            STORAGE_COUNT_KEY,
            JSON.stringify({
                count,
                productId,
                optionId: specConfig.optionId,
                optionName: specConfig.optionName,
            })
        );
    }
    /**
     *
     * @param {Array<{
     *  pdId: number,
     *  count: number,
     *  optionId: string,
     *  optionName: string[]
     * }>} list - 购物清单
     */
    static setShoppingList(list = []) {
        if (typeof window === 'undefined') {
            return;
        }
        window.sessionStorage.setItem(STORAGE_SHOPPING_LIST_KEY, JSON.stringify(list));
    }

    static getShoppingList() {
        if (typeof window === 'undefined') {
            return;
        }
        const shoppingList = window.sessionStorage.getItem(STORAGE_SHOPPING_LIST_KEY);
        if (shoppingList && typeof shoppingList === 'string') {
            try {
                return JSON.parse(shoppingList);
            } catch (error) {
                console.error(`get shopping list error: ` + error);
                window.sessionStorage.removeItem(STORAGE_SHOPPING_LIST_KEY);
                return [];
            }
        } else {
            return [];
        }
    }

    static getStoreInfo() {
        const payProductStr = window.localStorage.getItem(STORAGE_COUNT_KEY);
        if (payProductStr) {
            try {
                const payProductCount = JSON.parse(payProductStr);
                window.localStorage.removeItem(STORAGE_COUNT_KEY);
                return payProductCount;
            } catch (error) {
                console.error('Failed to parse payProductCount', error);
                window.localStorage.removeItem(STORAGE_COUNT_KEY);
            }
        }
        return null;
    }

    static checkProductCanBuy(productId, optionId) {
        return new Promise((resolve) => {
            checkProductCanBuy({ pdId: productId, optionId }).then((data) => {
                if (data.success) {
                    if (data.pdNotFound) {
                        warningMessage(window.LS.productDeleteTips);
                        resolve(false);
                    } else if (data.isNotAdd) {
                        warningMessage(window.LS.productDelistTips);
                        resolve(false);
                    } else if (!data.isjzPayProductType) {
                        warningMessage(window.LS.notCommerceProduct);
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                } else {
                    resolve(false);
                }
            });
        });
    }

    static async addToCart(productId, shoppingCartUrl, amount, spec) {
        amount = parseInt(amount ?? 1);
        spec = spec ?? {};

        if (amount > MAX_LIMIT || amount < MIN_LIMIT) {
            return;
        }
        let result = await addToCart(productId, amount, spec);
        const isMobi = window._store.state.device === 'mobi';
        if (result.success) {
            if (!isMobi) {
                createDialog({
                    content: LS.add_To_cart || '添加成功',
                    type: 'success',
                    autoHide: false,
                    confirmButtonText: LS.go_settle || '去结算',
                    cancelButtonText: LS.continue_shopping || '继续购物',
                    useTheme: true,
                    onConfirm: () => {
                        window.location.href = shoppingCartUrl;
                    },
                    layoutClass: 'cart_popup',
                });
            } else if (isMobi && !$('.jz-modal-root').length) {
                Vue.prototype.$modal.show({
                    title: LS.add_To_cart || '添加成功',
                    onCancel() {},
                    onConfirm() {
                        window.location.href = shoppingCartUrl;
                    },
                    env: 'mobile',
                    getContainer() {
                        return document.querySelector('.jz_preview_area');
                    },
                    confirmButtonText: LS.go_settle || '去结算',
                    cancelButtonText: LS.continue_shopping || '继续购物',
                });
            }
        } else {
            let { rt } = result;
            warningMessage(rt == -4 ? window.LS.cartIsFull || '购物车已满' : result.msg, true);
        }
    }

    /**
     *
     * @param {number[]} delPdIdList
     * @param {number[]} selectPdIdList
     * @param {{ pdId: number, optionId: string }[]} selectPdOptionIdList
     * @returns
     */
    static async delProductForCart(delPdIdList, selectPdIdList, delPdOptionIdList, selectPdOptionIdList = []) {
        let result = await delProductForCart(delPdIdList, selectPdIdList, delPdOptionIdList, selectPdOptionIdList);
        if (!result.success) {
            warningMessage(result.msg, true);
        }
        return result;
    }

    static async delAllCartProduct() {
        let result = await delAllProductForCart();
        if (!result.success) {
            warningMessage(result.msg, true);
        }
        return result;
    }

    static async setCart(pdId, amount, selectPdIdList, optionId, selectPdOptionIdList) {
        if (amount > MAX_LIMIT || amount < MIN_LIMIT) return;
        let result = await setCart(pdId, amount, selectPdIdList, optionId, selectPdOptionIdList);
        if (!result.success) {
            let { rt } = result;
            warningMessage(rt == -4 ? window.LS.cartIsFull || '购物车已满' : result.msg, true);
        }
        return result;
    }

    static async calculateCartPrice(pdIdList, selectPdOptionIdList = []) {
        let result = await calculateCartPrice(pdIdList, selectPdOptionIdList);
        if (!result.success) {
            // warningMessage(result.msg);
        }
        return result;
    }

    static async calculateItemListPrice(itemList) {
        let result = await calculateItemListPrice(itemList);
        if (!result.success) {
            // warningMessage(result.msg);
        }
        return result;
    }

    /**
     * 根据购物清单获取所有购物信息，包括总价格
     * @param {Array<{
     *  pdId: number,
     *  count: number,
     *  optionId: string,
     *  optionName: string[]
     * }>} shoppingList - 购物清单
     */
    static async getShoppingInfo(shoppingList = []) {
        const result = await getShoppingInfo(shoppingList);
        if (result.success === true) {
            return {
                list: result.itemList,
                totalPrice: result.totalPrice,
            };
        } else {
            throw new Error(result.msg);
        }
    }
}

/**
 * 收货信息类型
 */
PayProduct.payPropTypes = {
    SYSTEM_NAME: 1,
    SYSTEM_ADDRESS: 2,
    SYSTEM_PHONE: 3,
    SYSTEM_MAIL: 4,
    TEXT: 5,
    PHONE: 6,
    MAIL: 7,
};

PayProduct.payProductStatus = {
    /**
     * 待付款
     */
    WAIT_PAY: 1,
    /**
     * 待发货
     */
    WAIT_SEND: 2,
    /**
     * 待收货
     */
    SENT: 3,
    /**
     * 交易完成
     */
    FINISHED: 4,
    /**
     * 交易关闭
     */
    CLOSED: 5,

    /**
     * 临时状态，用于支付成功后检查支付状态
     */
    PAY_SUCCESS: -1,
};
