export default {
    namespaced: true,
    state: () => ({
        anchor: {
            moduleId: -1,
            rowId: -1,
        },
        pause: false,
    }),
    mutations: {
        setActiveAnchor(state, { moduleId = -1, rowId = -1 }) {
            state.anchor.moduleId = moduleId;
            state.anchor.rowId = rowId;
        },
        pause(state, value) {
            state.pause = value;
        },
    },
};
