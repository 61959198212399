export default {
    isMobi: (state) => state.device === 'mobi',
    info(state) {
        return state.responsiveInfo;
    },
    responsiveInfoPattern(state) {
        return state.responsiveInfo.pattern;
    },
    globalRowInfo(state, getters) {
        return getters.responsiveInfoPattern.globalRowInfo;
    },
    isFullScreen: (state, getters) => {
        return state.fullScreen && (state.device == 'pc' || getters.responsiveInfoPattern.rollingScreen.isMobiSync);
    },
    /**
     * @function name - hasLastFullSiteRow
     * @description
     * 判断栏目是否有末个全站通栏
     * 非全屏栏目且lastAllRowId不为0
     * @param [allFlag = true]{boolean}; 是否需要判断全站  默认是判断全站 false 判断该栏目
     */
    hasLastFullSiteRow:
        (state, getters) =>
        (allFlag = true) => {
            const { globalRowInfo } = getters;
            return (
                !state.fullScreen &&
                globalRowInfo.lastAllRowId !== 0 &&
                (allFlag || !globalRowInfo.delColIdsLast.includes(state.colId))
            );
        },
    /**
     * @function name - hasFirstFullSiteRow
     * @description
     * 判断栏目是否有首个全站通栏
     * @param [allFlag = true]{boolean}; 是否需要判断全站  默认是判断全站 false 判断该栏目
     */
    hasFirstFullSiteRow:
        (state, getters) =>
        (allFlag = true) => {
            const { globalRowInfo } = getters;
            return (
                !state.fullScreen &&
                globalRowInfo.firstAllRowId !== 0 &&
                (allFlag || !globalRowInfo.delColIdsFirst.includes(state.colId))
            );
        },
    /**
     * @function name - isFirstFullSiteRow
     * @description
     * 判断该通栏是否是首个全站通栏
     * 非全屏栏目且firstAllRowId不为0且delColIdFirst 不存在此栏目id
     * @param [rowId =0]{number};
     */
    isFirstFullSiteRow:
        (state, getters) =>
        (rowId = 0) => {
            return (
                !state.fullScreen && getters.globalRowInfo.firstAllRowId === rowId && getters.hasFirstFullSiteRow(false)
            );
        },
    /**
     * @function name - isLastFullSiteRow
     * @description
     * 判断该通栏是否是末个全站通栏
     * 非全屏栏目且lastAllRowId不为0且delColIdLast 不存在此栏目id
     * @param [rowId =0]{number};
     */
    isLastFullSiteRow:
        (state, getters) =>
        (rowId = 0) => {
            return (
                !state.fullScreen && getters.globalRowInfo.lastAllRowId === rowId && getters.hasLastFullSiteRow(false)
            );
        },
    /**
     * @function name - isFullSiteRow
     * @description
     * 判断该通栏是否是全站通栏
     * 非全屏栏目且不是首个全站或末个全站
     * @param [rowId =0]{number};
     */
    isFullSiteRow:
        (state, getters) =>
        (rowId = 0) => {
            return !state.fullScreen && (getters.isLastFullSiteRow(rowId) || getters.isFirstFullSiteRow(rowId));
        },

    /**
     * @function name - isNestRow
     * @description 判断是否嵌套根的通栏（也就是放在rows容器内的）
     * @param rowId {Number} 行id
     */
    isNestRootRow: (state) => (rowId) => {
        return state.rows['row' + rowId].cols[0].type == 1;
    },
    /**
     * @function name - isNestRow
     * @description 判断是否嵌套的行
     * @param rowId {Number} 行id
     */
    isNestRow: (state) => (rowId) => {
        const { rowIds, headerRowIds, footerRowIds, pattern } = state.responsiveInfo;
        let navColList = state.sections.navColList || [],
            navFreeLayoutRowIds = navColList
                .map((navItem) => navItem.rIds || [])
                ?.reduce((pre, cur) => {
                    return pre.concat(cur);
                }, []);
        // 不存在一级行内
        return !(
            // 只要从一个列表里能找到就是true
            (
                rowIds.includes(rowId) ||
                headerRowIds.includes(rowId) ||
                footerRowIds.includes(rowId) ||
                pattern.nav.rl.map(({ id }) => id).includes(rowId) ||
                navFreeLayoutRowIds.includes(rowId)
            )
        );
    },

    /**
     * @function name - getRowBelong
     * @description 获取行的类型
     * @param id {Number} 行id
     * @param flag {Boolean} 是否将横幅通栏归入rowIds
     */
    getRowBelong:
        (state) =>
        (id, flag = false) => {
            let dict = ['headerRowIds', 'footerRowIds', 'rowIds'],
                i,
                match,
                _row = state.rows['row' + id];

            for (i = 0; i < dict.length; i++) {
                match = state.responsiveInfo[dict[i]].indexOf(id);

                if (match > -1) {
                    return {
                        rows: state.responsiveInfo[dict[i]],
                        index: match,
                        name: dict[i],
                    };
                }
            }

            if (flag) {
                match = _row && _row.type === 5;

                if (match) {
                    return {
                        rows: state.responsiveInfo['rowIds'],
                        index: state.responsiveInfo['rowIds'].indexOf(id),
                        name: 'bannerRowIds',
                    };
                }
            }

            const navRl = state.responsiveInfo.pattern.nav.rl;
            if (navRl[navRl.length - 1]?.id === id) {
                return {
                    rows: state.responsiveInfo['rowIds'],
                    index: state.responsiveInfo['rowIds'].indexOf(id),
                    name: 'fullNavRowIds',
                };
            }

            if (state.popupZone.rowId === id) {
                return {
                    rows: state.responsiveInfo['rowIds'],
                    index: state.responsiveInfo['rowIds'].indexOf(id),
                    name: 'popupZoneRowIds',
                };
            }

            return null;
        },

    /**
     * @function isSysRow
     * @description 是否是系统行
     * @param {Number} rowId
     */
    isSysRow: (state, getters) => (rowId) => {
        const match = getters.getRowBelong(rowId, true);
        let isSysRow = false;
        if (!match) return isSysRow;
        switch (match.name) {
            case 'headerRowIds':
            case 'footerRowIds':
                isSysRow = true;
                break;
            default:
                break;
        }
        return isSysRow;
    },

    /**
     * 是否允许设置效果的通栏
     */
    isEffectRow: (state, getters) => (rowId) => {
        if (!state.rows[`row${rowId}`]) {
            return false;
        }

        const rowType = state.rows[`row${rowId}`].type;
        let fullScreenPass = false;

        // 横向全屏 + Mobi 视图不允许设置效果通栏
        if (getters.isMobi === true && getters.info.pattern.rollingScreen.type === 2) {
            return false;
        }

        if (getters.isFullScreen) {
            if (getters.info.pattern.rollingScreen.type === 1) {
                // 全屏通栏纵向开放
                fullScreenPass = true;
            } else {
                return false;
            }
        } else {
            fullScreenPass = true;
        }
        if (rowId === 4) {
            // 底部通栏
            return true;
        }
        const isNormalContentRow = state.responsiveInfo.rowIds.includes(rowId);
        return (
            fullScreenPass &&
            !getters.isSysRow(rowId) &&
            !getters.isNestRow(rowId) &&
            rowType === 1 &&
            isNormalContentRow
        );
    },

    /**
     * @function getRowEffectType
     * @description 获取行类型
     * @param {Number} rowId
     */
    getRowEffectType: (state, getters) => (rowId) => {
        const { pattern } = state.rows[`row${rowId}`];
        if (getters.isMobi && pattern.mobi.e.t === 0) {
            return pattern.pc.e.t;
        } else {
            return pattern[state.device].e.t;
        }
    },

    /**
     * 是否轮播通栏
     */
    isCarouselRow: (state, getters) => (rowId) => {
        return getters.isEffectRow(rowId) && getters.getRowEffectType(rowId) === 3;
    },

    /**
     * 是否显示视频背景
     */
    isShowVideoBg: (state, getters) => (row) => {
        if (getters.isMobi) {
            return false;
        }
        const rowBackground = row.pattern.pc.rb;
        return rowBackground.y === 2 && rowBackground.bt === 1 && rowBackground.vpath;
    },

    /**
     *
     *  是否开启SEO 优化 - 删除 url 上的 hash
     */
    hashRemoved(state) {
        return state.openHtmlUrl && state.setReqHttpOnlyCookie;
    },

    /**
     * 获取嵌套通栏的根通栏id，不是嵌套通栏则返回-1
     */
    getNestRootRowId: (state) => (rowId) => {
        const rowKeys = Object.keys(state.rows);
        let result = -1;
        outer: for (let i = 0; i < rowKeys.length; i++) {
            const row = state.rows[rowKeys[i]];
            const queue = [row];
            while (queue.length) {
                const r = queue.shift();
                for (let j = 0; j < r.cols.length; j++) {
                    const col = r.cols[j];
                    for (let k = 0; k < col.rIds.length; k++) {
                        const nestRowId = col.rIds[k];
                        if (nestRowId === rowId) {
                            result = row.id;
                            break outer;
                        }
                        if (state.rows[`row${nestRowId}`]) {
                            queue.push(state.rows[`row${nestRowId}`]);
                        }
                    }
                }
            }
        }
        return result;
    },

    /**
     * 通栏是否开启垂直对齐
     */
    isRowOpenVerticalAlign: (state, getters) => (row) => {
        const rootId = getters.getNestRootRowId(row.id);
        let isNest = false;
        let isNestRootRow = false;
        if (rootId !== -1) {
            isNest = true;
            row = state.rows[`row${rootId}`];
        } else {
            isNestRootRow = row.cols.some((col) => col.rIds.length > 0);
        }
        if (!row) {
            return false;
        }
        if (row.cols.length < 2 && !isNest && !isNestRootRow) {
            return false;
        }
        return row.pattern.pc.ai !== 0;
    },

    /**
     * 轮播通栏是否开启垂直对齐
     */
    isCarouselRowOpenVerticalAlign:
        (state) =>
        (rowId, carouselIndex = 0) => {
            let rowData = state.rows[`row${rowId}`];
            const carouselData = rowData.pattern.pc.e.rbs[carouselIndex];
            const isNestRow = carouselData.cols.some((col) => col.rIds.length > 0);
            if (carouselData.cols.length < 2 && !isNestRow) {
                return false;
            }
            return carouselData.ai !== 0;
        },

    /**
     * 页面上显示的通栏
     */
    showRows(state, getters) {
        let result = [];
        const { responsiveInfo, rows } = state;
        if (!responsiveInfo) {
            return result;
        }
        const { headerRowIds, rowIds, footerRowIds } = responsiveInfo;
        if (getters.isMobi) {
            result = rowIds.concat(footerRowIds);
            const { showMobiHideRow } = state.row;
            if (!showMobiHideRow) {
                result = result.filter((id) => !rows[`row${id}`]?.pattern.mobi.mh);
            }
        } else {
            result = headerRowIds.concat(rowIds, footerRowIds);
        }
        result = result.filter((id) => Boolean(rows[`row${id}`]));
        return result;
    },
    isSupportImageService: (state) => (path) => {
        return (
            /(\.faiusr\.com)|(\.aaadns\.com)/.test(path) &&
            !/(\/materialLib\/)/.test(path) &&
            !path.includes(state.resRoot)
        );
    },
    img2Webp:
        (state, getters) =>
        (url, isSupportWebp = true) => {
            if (!isSupportWebp || !state.webpOptOpen || getters.isSupportImageService(url) === false) {
                return url;
            }

            return url.replace(
                /(jpg|jpeg|png|gif)$|(jpg|jpeg|png|gif)\?v=|(jpg|jpeg|png|gif)\?_tm=/,
                function (match, key1, key2, key3) {
                    if (key1) {
                        // 匹配图片结尾，没有带版本号
                        return key1 + '.webp';
                    } else if (key2) {
                        // 匹配图片结尾，有带版本号，如 png?v=2018
                        return match.replace(key2, key2 + '.webp');
                    } else if (key3) {
                        // 匹配图片结尾，有带版本号，如 png?v=2018
                        return match.replace(key3, key3 + '.webp');
                    }
                }
            );
        },
    isCn(state) {
        return state.lcid == 2052 || state.lcid == 1028;
    },
    seoPlatformName(state, getters) {
        return getters.isCn ? '百度' : '谷歌';
    },
    isAddRowDefaultSetAdaptWidth(state) {
        const pattern = state.responsiveInfo.pattern;
        return pattern.adaptWidth.io === 1 && pattern.adaptWidth.nroa;
    },
};
