var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ClientOnly, [
    _c(
      "div",
      { staticClass: "shopping_cart" },
      [
        _c("div", { staticClass: "shopping_section left" }, [
          _c("div", {
            staticClass: "label",
            domProps: { textContent: _vm._s(_setup.shoppingCart) },
          }),
          _vm._v(" "),
          _setup.productList.length
            ? _c(
                "div",
                { staticClass: "shopping_list" },
                [
                  _c(_setup.ShoppingList, {
                    attrs: {
                      list: _setup.productList,
                      "can-modify": true,
                      "unit-info": _setup.unitInfo,
                      check: true,
                      del: true,
                    },
                    on: {
                      check: _setup.checkProduct,
                      count: _setup.countChange,
                      del: _setup.delProduct,
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mall_order_detail_split_line" }),
                  _vm._v(" "),
                  _setup.isMobi
                    ? [
                        _c("div", { staticClass: "back_shopping" }, [
                          _c(
                            "svg",
                            {
                              staticStyle: {
                                "margin-right": "6px",
                                width: "8px",
                                height: "8px",
                              },
                            },
                            [_c("use", { attrs: { href: "#arrow_left" } })]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _setup.backPage,
                              },
                            },
                            [_vm._v(_vm._s(_setup.LS.continueShopping))]
                          ),
                        ]),
                      ]
                    : [
                        _c("div", { staticClass: "shopping_cart_bottom" }, [
                          _c(
                            "div",
                            {
                              staticClass: "shopping_check",
                              on: { click: _setup.selectAll },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "checkbox_item",
                                  class: _setup.selectAllClass,
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "checkbox_inner jz_theme_bg_color faisco-icons-S000335",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "checkbox_inner_text" },
                                    [_vm._v(_vm._s(_setup.LS.productSelectAll))]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ],
                ],
                2
              )
            : _c("div", { staticClass: "shopping_list_empty" }, [
                _c("div", { staticClass: "empty_img" }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "empty_tips",
                  domProps: { textContent: _vm._s(_setup.cartIsEmpty) },
                }),
              ]),
        ]),
        _vm._v(" "),
        _setup.isMobi
          ? [
              _setup.productList.length
                ? _c(
                    "div",
                    { staticClass: "total", class: _setup.totalWrapClass },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "shopping_check",
                          on: { click: _setup.selectAll },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "checkbox_item",
                              class: _setup.selectAllClass,
                            },
                            [
                              _c("span", {
                                staticClass:
                                  "checkbox_inner jz_theme_bg_color faisco-icons-S000335",
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "checkbox_inner_text" },
                                [_vm._v(_vm._s(_setup.LS.productSelectAll))]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "shopping_total" }, [
                        _c("span", { staticClass: "pay_tips" }, [
                          _vm._v(_vm._s(_setup.LS.totalMoeny)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "price" }, [
                          _vm._v(_vm._s(_setup.addUnit(_setup.total))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "pay_btn",
                            on: { click: _setup.goPay },
                          },
                          [_vm._v(_vm._s(_setup.LS.orderListOptStl))]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          : [
              _setup.productList.length
                ? _c("div", { staticClass: "shopping_section right" }, [
                    _c("div", {
                      staticClass: "label",
                      domProps: { textContent: _vm._s(_setup.LS.total) },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "total" }, [
                      _c(
                        "div",
                        { staticClass: "mall_order_detail_total_item" },
                        [
                          _c("span", [_vm._v(_vm._s(_setup.LS.productSum))]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_setup.addUnit(_setup.total))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "mall_order_detail_split_line",
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mall_order_detail_total_item" },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_setup.LS.shouldPayPrice)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "mall_order_detail_price" },
                            [_vm._v(_vm._s(_setup.addUnit(_setup.total)))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "mall_order_detail_submit",
                          on: { click: _setup.goPay },
                        },
                        [_vm._v(_vm._s(_setup.LS.orderListOptStl))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "back_shopping" }, [
                        _c(
                          "svg",
                          {
                            staticStyle: {
                              "margin-right": "6px",
                              width: "8px",
                              height: "8px",
                            },
                          },
                          [_c("use", { attrs: { href: "#arrow_left" } })]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { target: "_blank", href: _setup.backPage },
                          },
                          [_vm._v(_vm._s(_setup.LS.continueShopping))]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }