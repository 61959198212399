
import Price from './Price.vue';
export default {
    name: 'RangePrice',
    components: {
        Price,
    },
    props: {
        minPrice: {
            type: [Number, String],
            default: 0,
        },
        maxPrice: {
            type: [Number, String],
            default: 0,
        },
    },
    computed: {
        isRangePrice() {
            return this.minPrice !== this.maxPrice;
        },
        singlePrice() {
            return this.minPrice; // minPrice or maxPrice
        },
        attributes() {
            return {
                attrs: this.$attrs,
                listeners: this.$listeners,
            };
        },
    },
    methods: {
        renderSinglePrice() {
            return <Price price={this.singlePrice} {...this.attributes} />;
        },
        renderRangePrice() {
            return (
                <div class="range-price">
                    <Price price={this.minPrice} {...this.attributes} />
                    <span class="price_sep">-</span>
                    <Price price={this.maxPrice} {...this.attributes} />
                </div>
            );
        },
    },
    render() {
        return this.isRangePrice ? this.renderRangePrice() : this.renderSinglePrice();
    },
};
