import { debounce, digGet, throttle } from '@jz/utils';
import { JzFdpLog } from '@/site/shared/log/index.js';
import { getState } from '../store';
import { _voidVm } from '../vueEventBus';
import { GLOBAL_FONT_FAMILY_NAME_CSS_VALUE } from '@/store/modules/manage/modules/globalFont';
const FIX_TOP_TEMP_CLASS = 'fix_top_class';
let init_fix_top_style = false; // 初始化固定顶部样式，避免多次渲染样式
let navRowId = 0; // 记录导航 rowId，避免重复查找
let mouseInHeader = false; // 鼠标 hover 到顶部触发固定效果

const FIX_TOP_HEADER_SETTING = 'jz_fixed_top';
const FIX_TOP_STYLE_SETTING = 'jz_fixed_customize';
const FIX_TOP_GRID_HEADER_CLASS = 'g_header_content_temp__fixed';

const fontSettingProperties = [
    'color',
    'text-decoration',
    'font-weight',
    'font-style',
    'background-image',
    '-webkit-background-clip',
    '-webkit-text-fill-color',
];

// 发送埋点
const sendFixTopFdp = (eventName, freeText) => {
    if (window._store.state.manageMode) {
        let jz_zsy_page = '常规栏目';
        switch (window._store.state.fullScreen) {
            case 1:
                jz_zsy_page = '纵向滚屏';
                break;
            case 2:
                jz_zsy_page = '横向滚屏';
                break;
            default:
                break;
        }
        JzFdpLog.logJzFdp(eventName, {
            jz_content_terminal: '自适应',
            jz_free_text1: freeText,
            jz_version: window._store.state.jzVersion,
            jz_zsy_page,
        });
    }
};

const isHtmlElement = (el) => {
    return el && el.nodeType == Node.ELEMENT_NODE;
};

// 获取目标节点所在容器的rowId
const findParentRowId = (el, endParent = document.body) => {
    if (!isHtmlElement(el)) return 0;
    let parentNode = el.parentNode;
    if (parentNode != null) {
        let isRow = parentNode.classList.contains('jz_web_row');
        if (isRow) {
            let arr = parentNode.id.split('row');
            return arr[arr.length - 1];
        } else {
            // eslint-disable-next-line no-unused-vars
            return parentNode == endParent ? 0 : findParentRowId(parentNode, endParent);
        }
    } else {
        return 0;
    }
};

// 判断目标节点是否在导航所在通栏，语言导航、网站标题、文本模块
const isElInNavRow = (rowId) => {
    let navEl = $('#gridHeader .jz_nav_wrap')[0];
    if (navEl == null) {
        navRowId = 0;
    } else {
        if (navRowId == 0) {
            navRowId = findParentRowId(navEl);
        }
    }
    if (navRowId != 0) {
        return rowId == navRowId;
    } else {
        return false;
    }
};

// 检测当前 顶部是否是固定状态
const currentPageIsFixed = () => {
    // 普通栏目
    let isFullScreen = getState('fullScreen');

    if (window._store.state.manageMode) {
        let fixTop = isFullScreen
            ? digGet(window._store.state.responsiveInfo, ['pattern', 'rollingScreen', 'fixedTop'])
            : digGet(window._store.state.responsiveInfo, ['pattern', 'header', 'fixedTop']);
        return fixTop;
    } else {
        return isFullScreen ? window._store.state.fullScreenOpenFixed : window._store.state.header.isOpenFixedTop;
    }
};

const getHeaderModuleIds = () => {
    let moduleDomList = Array.from($('#gridHeader .jz_module'));
    return moduleDomList.map((item) => {
        return parseInt($(item).attr('projectid')) || 0;
    });
};

// 判断当前模块是否在顶部中
const moduleInHeader = (moduleId) => {
    let moduleIdList = getHeaderModuleIds();
    return moduleIdList.includes(moduleId);
};

//	正则匹配中，特殊字符 * . ?  $ ^ [ ] ( ) { } | \ /
//	针对以上特殊字符，进行相应转义处理
function fixRegSpecialCharacter(cls) {
    var specialCharacter = ['\\', '.', '?', '$', '*', '^', '[', ']', '{', '}', '|', '(', ')', '/'];
    let resultCls = '';
    Array.from(cls).forEach((item) => {
        resultCls += specialCharacter.indexOf(item) != -1 ? '\\' + item : item;
    });
    return resultCls;
}

// 更新样式表
const updateCssStyle = (css) => {
    let id = 'fixTop';
    let doc = window.document;
    let style = $(`#${id}`);
    let list = new Array();
    if (style.length == 1) {
        // ie6下会把没换行的也自动加了换行，例如#id .xxx{\n\tDISPLAY: none\n}
        let html = style.html();
        html = html.replace(/{\r\n/g, '{').replace(/\t/g, '').replace(/\r\n}/g, ';}');
        list = html.split('\n');
        style.remove();
    }

    for (let i = list.length - 1; i >= 0; --i) {
        let line = list[i];
        for (let n = 0; n < css.length; ++n) {
            let cls = css[n].cls;
            let key = css[n].key;
            let reg = new RegExp(fixRegSpecialCharacter(cls) + ' *{ *' + key + '[^;]*;', 'gi');

            if (line.length == 0 || /^\s$/.test(line) || reg.test(line)) {
                list.splice(i, 1);
                break;
            }
        }
    }
    for (let n = 0; n < css.length; ++n) {
        if (css[n].value != '') {
            list.push(css[n].cls + '{' + css[n].key + ':' + css[n].value + ';}');
        }
    }
    $(doc.querySelector('head')).append('<style type="text/css" id="' + id + '">' + list.join('\n') + '</style>');
};

// 组装css样式
const composeCssStyle = (cls, fts, option = {}) => {
    let { c = '', d = 0, i = 0, w = 0 } = fts || {};
    let { renderColor = true } = option;
    let styleList = [];

    styleList.push({
        cls,
        key: 'text-decoration',
        value: d == 0 ? 'none' : 'underline !important',
    });
    styleList.push({
        cls,
        key: 'font-style',
        value: i == 0 ? 'normal' : 'italic !important',
    });
    styleList.push({
        cls,
        key: 'font-weight',
        value: w == 0 ? 'normal' : 'bold !important',
    });

    // 颜色若为普通颜色，则设置color；若颜色为渐变色，则设置background-image
    if (renderColor) {
        if (c.indexOf('gradient') == -1) {
            styleList.push(
                ...[
                    { cls, key: 'color', value: `${c} !important` },
                    { cls, key: 'background-image', value: '' },
                    { cls, key: '-webkit-background-clip', value: '' },
                    { cls, key: '-webkit-text-fill-color', value: '' },
                ]
            );
        } else {
            styleList.push(
                ...[
                    { cls, key: 'color', value: '' },
                    { cls, key: 'background-image', value: `${c} !important` },
                    {
                        cls,
                        key: '-webkit-background-clip',
                        value: 'text !important',
                    },
                    {
                        cls,
                        key: '-webkit-text-fill-color',
                        value: 'transparent !important',
                    },
                ]
            );
        }
    }
    return styleList;
};

// 解析obj对象，并通过cls拼装成 styleList 返回
const cssObjToCssStyleList = (cls, styleObj) => {
    let styleList = [];
    for (const [key, value] of Object.entries(styleObj)) {
        let tempValue = value != '' && value.indexOf('important') == -1 ? `${value} !important` : value;
        styleList.push({ cls, key, value: tempValue });
    }
    return styleList;
};

// 解析背景设置项，通过cls拼装成 styleList 返回
const composeBgSettingToStyleList = (cls, bgObj) => {
    let repeatDict = ['repeat', 'no-repeat', 'repeat-x', 'repeat-y'];
    let positionDict = [
        'top left',
        'top center',
        'top right',
        'center left',
        'center center',
        'center right',
        'bottom left',
        'bottom center',
        'bottom right',
    ];
    let sizeDict = ['auto', '100% 100%', '100%', 'cover', 'contain'];

    let backgroundStyle = {};
    let { d, c, f, y, a, tc, path, s, r, tcp, cp, p } = bgObj;
    if (y == 1) {
        backgroundStyle['background'] = 'none';
    } else if (y == 2) {
        if (d == 0) {
            backgroundStyle['background'] = c;
        } else if (d == 1) {
            backgroundStyle['background'] = `
        -webkit-linear-gradient(${a}deg, ${c}, ${tc});
        -o-linear-gradient(${a}deg, ${c}, ${tc});
        -moz-linear-gradient(${a}deg, ${c}, ${tc});
        linear-gradient(${a}deg, ${c}, ${tc});
        `;
        } else if (d == 2) {
            if (f != null && f != '') {
                if (s == 3) {
                    backgroundStyle['background'] = `url(${path}) no-repeat center`;
                    backgroundStyle['background-size'] = 'cover';
                } else {
                    backgroundStyle['background-image'] = `url(${path})`;
                    backgroundStyle['background-repeat'] = `${repeatDict[r]}`;
                    backgroundStyle['background-position'] = `${positionDict[p]}`;
                    if (r == 1 && s == 0) {
                        backgroundStyle['background-color'] = `${c}`;
                    }
                    if (s != 0) {
                        backgroundStyle['background-size'] = `${sizeDict[s]}`;
                        if (s == 2) {
                            backgroundStyle['background-repeat'] = `${repeatDict[1]}`;
                        }
                    }
                }
            }
        } else if (d == 3) {
            // image
            if (f != null && f != '' && path != '' && path != null) {
                backgroundStyle['background-image'] = `url(${path})`;
            }
            if (tc != null && tc != '' && a != null && c != null && c != '') {
                let tempStr = backgroundStyle['background-image']
                    ? `${backgroundStyle['background-image']},linear-gradient(${a}deg, ${c} ${cp}%, ${tc} ${tcp}%)`
                    : `linear-gradient(${a}deg, ${c} ${cp}%, ${tc} ${tcp}%)`;
                backgroundStyle['background-image'] = `${tempStr} !important`;
            }
            // color
            if (tc == null || tc == '') {
                if (c != null && c != '') {
                    backgroundStyle['background-color'] = c;
                }
            }
            // size
            if (s != null && s != 0 && s < sizeDict.length) {
                backgroundStyle['background-size'] = sizeDict[s];
            }
            // repeat
            if (s != null) {
                if ([2, 3, 4].indexOf(s) != -1) {
                    backgroundStyle['background-repeat'] = repeatDict[1];
                } else {
                    backgroundStyle['background-repeat'] = repeatDict[r];
                }
            }
            // position
            if (s != null) {
                switch (s) {
                    case 4:
                        backgroundStyle['background-position'] = positionDict[4];
                        break;
                    case 3:
                        backgroundStyle['background-position'] = positionDict[4];
                        break;
                    case 1:
                        backgroundStyle['background-position'] = positionDict[3];
                        break;
                    default:
                        backgroundStyle['background-position'] = positionDict[p];
                        break;
                }
            }
        }
    } else if (y == 0) {
        // 默认全部修改置为空
        let keys = [
            'background',
            'background-size',
            'background-image',
            'background-repeat',
            'background-position',
            'background-color',
        ];
        keys.forEach((item) => {
            backgroundStyle[item] = '';
        });
    }
    return cssObjToCssStyleList(cls, backgroundStyle);
};

// 解析字体设置项，通过 cls 拼装成 styleList 返回
const composeFontSettingToStyleList = (cls, fontObj) => {
    let { s, f, w, i, d, y, ugf } = fontObj;
    let fontStyleObj = {};
    if (y == 0) {
        // 使用默认情况，不设置任何样式
        ['font-size', 'font-family', 'font-weight', 'font-style', 'text-decoration'].forEach((item) => {
            fontStyleObj[item] = '';
        });
    } else {
        fontStyleObj['font-size'] = `${s}px !important`;
        fontStyleObj['font-family'] = `${f} !important`;
        if (ugf === 1) {
            fontStyleObj['font-family'] = `${GLOBAL_FONT_FAMILY_NAME_CSS_VALUE} !important`;
        }
        if (w > 0) {
            fontStyleObj['font-weight'] = 'bold !important';
        }
        if (i > 0) {
            fontStyleObj['font-style'] = 'italic !important';
        }
        if (d > 0) {
            fontStyleObj['text-decoration'] = 'underline !important';
        }
    }
    return cssObjToCssStyleList(cls, fontStyleObj);
};

const getComputedTextlinearGradientColor = (color, useNew = true, needImportant = true) => {
    let obj = {};
    let important = needImportant ? '!important' : '';
    // 进行颜色格式化
    if (!useNew) {
        let colorCssKeys = ['background-image', 'color', '-webkit-background-clip', '-webkit-text-fill-color'];
        colorCssKeys.forEach((key) => {
            obj[key] = '';
        });
    } else {
        if (color != null && color != '') {
            if (color.indexOf('linear-gradient') > -1) {
                obj['background-image'] = `${color} ${important}`;
                obj['-webkit-background-clip'] = `text ${important}`;
                obj['-webkit-text-fill-color'] = `transparent ${important}`;
                obj['color'] = '';
            } else {
                obj['color'] = `${color} ${important}`;
                obj['background-image'] = `none ${important}`;
                obj['-webkit-background-clip'] = `unset ${important}`;
                obj['-webkit-text-fill-color'] = `unset ${important}`;
            }
        }
    }
    return obj;
};

// 字体颜色渐变处理
const fontColorStyle = (cls, color, useNew = true) => {
    let obj = getComputedTextlinearGradientColor(color, useNew);
    return cssObjToCssStyleList(cls, obj);
};

// 组装管理态导航的样式
const composeFixTopNavCssStyle = () => {
    let pattern = window._store.state.responsiveInfo.pattern;
    let fts = digGet(pattern, ['nav', 'fts']);
    let { cb, cbg, csbg, cf, ncc = {}, cplt, cpls, cplcp, nca } = fts;
    let { ibg, isbg } = ncc;
    let childItemFont = ncc.if;
    let baseCls = `.jz_web_header .${FIX_TOP_GRID_HEADER_CLASS} .jz_nav_wrap.fix_top_class`;
    let allStyleList = [];
    if (fts) {
        let navItemCls = `${baseCls} .jz_nav_item`;
        let navItemStyleList = [];
        let navItemStyle = {};
        // 圆角
        navItemStyle['border-radius'] = fts.cbrt == 1 ? `${fts.cbrv}px !important` : '';
        // 边框
        if (cb.y == 1) {
            navItemStyle['border-width'] = `${cb.w}px !important`;
            navItemStyle['border-top-style'] = cb.t ? 'solid !important' : 'none';
            navItemStyle['border-bottom-style'] = cb.b ? 'solid !important' : 'none';
            navItemStyle['border-left-style'] = cb.l ? 'solid !important' : 'none';
            navItemStyle['border-right-style'] = cb.r ? 'solid !important' : 'none';
        } else {
            let borderCssKeys = [
                'border-width',
                'border-top-style',
                'border-bottom-style',
                'border-left-style',
                'border-right-style',
                'border-color',
            ];
            borderCssKeys.forEach((key) => {
                navItemStyle[key] = '';
            });
        }
        navItemStyleList = cssObjToCssStyleList(navItemCls, navItemStyle);
        allStyleList.push(...navItemStyleList);
        allStyleList.push({
            cls: `${baseCls} li.jz_nav_item:not(.jz_nav_item_selected):not(.jz_nav_item_active)`,
            key: 'border-color',
            value: cb.y ? `${cb.c} !important` : '',
        });
        allStyleList.push({
            cls: `${baseCls} li.jz_nav_item_selected, ${baseCls} .jz_nav_list .jz_nav_item:hover`,
            key: 'border-color',
            value: cb.y ? `${cb.sc} !important` : '',
        });

        // 背景
        allStyleList.push(...composeBgSettingToStyleList(`${baseCls} .jz_nav_item`, cbg));
        allStyleList.push(
            ...composeBgSettingToStyleList(
                `${baseCls} .jz_nav_item.jz_nav_item_selected, ${baseCls} .jz_nav_item:hover`,
                csbg
            )
        );
        // 文字
        allStyleList.push(
            ...composeFontSettingToStyleList(`${baseCls} .jz_nav_item:not(.sub_nav_item) .nav_item_text`, cf)
        );
        // 正常文字颜色
        allStyleList.push(
            ...fontColorStyle(
                `${baseCls} .jz_nav_item:not(.jz_nav_item_selected):not(.jz_nav_item_active) .nav_item_text_wrap .nav_item_text`,
                cf.c,
                cf.y
            )
        );
        // 文字选中颜色深
        allStyleList.push(
            ...fontColorStyle(
                `${baseCls} .jz_nav .jz_nav_item_selected .nav_item_text_wrap > a, ${baseCls} .jz_nav .jz_nav_item:hover .nav_item_text_wrap .nav_item_text`,
                cf.sc,
                cf.y
            )
        );
        // 下划线
        if (cf.d > 0) {
            allStyleList.push({
                cls: `${baseCls} .jz_nav_item .nav_item_text`,
                key: 'text-decoration',
                value: cf.y != 0 ? 'underline !important' : '',
            });
        }
        // 项间距
        allStyleList.push({
            cls: `${baseCls} .nav_item_septal`,
            key: 'font-size',
            value: cf.y != 0 ? `${cf.s} !important` : '',
        });
        // 导航分割线
        let colPartitionClass = `.jz_nav_partition_line_style${cpls}`;
        if (cplt == 0) {
            allStyleList.push({
                cls: `${baseCls} .jz_nav_partition_line`,
                key: 'display',
                value: '',
            });
            allStyleList.push(...fontColorStyle(`${baseCls} .nav_item_septal span`, '', false));
            allStyleList.push({
                cls: `${baseCls} ${colPartitionClass}`,
                key: 'display',
                value: '',
            });
        } else if (cplt == 1) {
            allStyleList.push({
                cls: `${baseCls} .jz_nav_partition_line`,
                key: 'display',
                value: 'none !important',
            });
        } else {
            let { c, ft } = cplcp;
            if (c != null && !ft) {
                allStyleList.push(...fontColorStyle(`${baseCls} .nav_item_septal span`, c));
            }
            allStyleList.push({
                cls: `${baseCls} .jz_nav_partition_line`,
                key: 'display',
                value: 'none !important',
            });
            allStyleList.push({
                cls: `${baseCls} ${colPartitionClass}`,
                key: 'display',
                value: 'block !important',
            });
        }

        // 子级导航 - 默认背景 以及 选中背景
        allStyleList.push(...composeBgSettingToStyleList(`${baseCls} .sub_nav_list, ${baseCls} .three_nav_list`, ibg));
        allStyleList.push(
            ...[
                ...composeBgSettingToStyleList(
                    `${baseCls} .sub_nav_list_active.sub_nav_item, ${baseCls} .sub_nav_item:hover`,
                    isbg
                ),
                ...composeBgSettingToStyleList(
                    `${baseCls} .jz_nav_item_selected.sub_nav_item, ${baseCls} .sub_nav_item:hover`,
                    isbg
                ),
                ...composeBgSettingToStyleList(`${baseCls} .three_nav_item.jz_nav_item_selected`, isbg),
                ...composeBgSettingToStyleList(`${baseCls} .three_nav_item:hover`, isbg),
            ]
        );
        // 子级导航 - 文字设置项
        allStyleList.push(
            ...composeFontSettingToStyleList(
                `${baseCls} .jz_nav .sub_nav_list .sub_nav_item .sub_nav_item_text_wrap > a, ${baseCls} .jz_nav .three_nav_list .three_nav_item a.three_nav_item_text_wrap .three_nav_item_text`,
                childItemFont
            )
        );
        // 正常文字颜色
        allStyleList.push(
            ...fontColorStyle(
                `${baseCls} .jz_nav .sub_nav_list .sub_nav_item .sub_nav_item_text_wrap > a, ${baseCls} .jz_nav .three_nav_list .three_nav_item a.three_nav_item_text_wrap .three_nav_item_text`,
                childItemFont.c,
                childItemFont.y
            )
        );
        // 文字选中颜色深
        let selectColorClass = `
            ${baseCls} .jz_nav .sub_nav_list .jz_nav_item_selected .sub_nav_item_text_wrap > a,
            ${baseCls} .jz_nav .sub_nav_item:hover .sub_nav_item_text_wrap > a,
            ${baseCls} .jz_nav .three_nav_list .jz_nav_item_selected a.three_nav_item_text_wrap .three_nav_item_text,
            ${baseCls} .jz_nav .three_nav_item:hover a.three_nav_item_text_wrap .three_nav_item_text
        `;
        allStyleList.push(...fontColorStyle(`${selectColorClass}`, childItemFont.sc, childItemFont.y));
        // 内容对齐
        let align = '';
        if (nca != null && nca != -1) {
            align = nca == 0 ? 'left' : 'center';
        }
        allStyleList.push({
            cls: `${baseCls} .jz_nav_list`,
            key: 'text-align',
            value: align,
        });
    }
    return allStyleList;
};

// 获取当前滚动高度
const getScrollTop = () => {
    return $(document).scrollTop();
};

const allowToAddClass = (el) => {
    if (el != null && window._store.state.header.isOpenFixedHeight) {
        let rowId = findParentRowId(el);
        if (!isElInNavRow(rowId)) {
            return false;
        }
    }

    if (getState('fullScreen')) {
        return (
            getState('responsiveInfo').pattern.rollingScreen.fixedTop &&
            (window._store.state.row.rollingScreenRowInfo.curIndex > 0 || mouseInHeader === true)
        );
    } else {
        return getScrollTop() > 0 || mouseInHeader === true;
    }
};

// 获取顶部中的文本模块
const getTextModuleFromHeader = () => {
    let textModules = $('#gridHeader .jz_module_style_6');
    return Array.from(textModules);
};

// 动态添加 .fix_top_class 类 控制滚动样式
const updateFixTopWebTitleStyle = (setting = false) => {
    let titleEl = $('#gridHeader .jz_website_title');
    let allow = allowToAddClass(titleEl[0]);
    if (!init_fix_top_style || setting) {
        let titlePattern = window._store.state.responsiveInfo.pattern.title || {};
        let { fts = {} } = titlePattern;
        let styleList = [];
        let cls = `.${FIX_TOP_GRID_HEADER_CLASS} .jz_website_title.${FIX_TOP_TEMP_CLASS} .fk-editor *`;
        if (fts.t == 1) {
            styleList = composeCssStyle(cls, fts.f);
        } else {
            styleList = fontSettingProperties.map((key) => {
                return {
                    cls,
                    key,
                    value: '',
                };
            });
        }
        updateCssStyle(styleList);
        if (setting) {
            sendFixTopFdp(FIX_TOP_STYLE_SETTING, '网站标题');
        }
    }
    if (allow) {
        titleEl.addClass(FIX_TOP_TEMP_CLASS);
    }
};

const resetFixTopWebTitleStyle = () => {
    let titleEl = $('#gridHeader .jz_website_title');
    titleEl.removeClass(FIX_TOP_TEMP_CLASS);
};

const updateFixTopTextModuleById = (moduleId, setting = false) => {
    let moduleEl = $(`#module${moduleId} .module_text_content`);
    if (window._store.state.manageMode && (!init_fix_top_style || setting)) {
        let moduleData = window._store.state.modules[`module${moduleId}`];
        if (moduleData) {
            let fts = digGet(moduleData, ['privatePattern', 'pc', 'fts']);
            if (fts) {
                let cls = `.${FIX_TOP_GRID_HEADER_CLASS} #module${moduleId} .${FIX_TOP_TEMP_CLASS} *`;
                let styleList = [];
                if (fts.t == 1) {
                    styleList = composeCssStyle(cls, fts.f);
                } else {
                    styleList = fontSettingProperties.map((key) => {
                        return {
                            cls,
                            key,
                            value: '',
                        };
                    });
                }
                updateCssStyle(styleList);
                if (setting) {
                    sendFixTopFdp(FIX_TOP_STYLE_SETTING, '文本模块');
                }
            }
        }
    }
    if (allowToAddClass(moduleEl[0])) {
        moduleEl.addClass(FIX_TOP_TEMP_CLASS);
    }
};

const updateFixTopTextModuleStyle = () => {
    let textModulesElList = getTextModuleFromHeader();
    textModulesElList.forEach((module) => {
        // id格式：module741
        let id = $(module).attr('id') || '';
        let arr = id.split('module');
        let moduleId = arr[arr.length - 1];
        if (!isNaN(moduleId)) {
            updateFixTopTextModuleById(moduleId);
        }
    });
};

const resetFixTopTextModuleStyle = () => {
    let textModulesElList = getTextModuleFromHeader();
    textModulesElList.forEach((moduleEl) => {
        $(moduleEl).find('.module_text_content').removeClass(FIX_TOP_TEMP_CLASS);
    });
};

const updateFixTopNavStyle = (setting = false) => {
    let navEl = $('#gridHeader .jz_nav_wrap');
    if (!init_fix_top_style || setting) {
        // 获取导航数据
        let styleList = composeFixTopNavCssStyle();
        updateCssStyle(styleList);
        if (setting) {
            sendFixTopFdp(FIX_TOP_STYLE_SETTING, '导航栏');
        }
    }
    if (allowToAddClass()) {
        navEl.addClass(FIX_TOP_TEMP_CLASS);
        _voidVm.$emit('responseNav');
    }
};

const resetFixTopNavStyle = () => {
    let navEl = $('#gridHeader .jz_nav_wrap');
    navEl.removeClass(FIX_TOP_TEMP_CLASS);
    _voidVm.$emit('responseNav');
};

const updateFixTopLanguageStyle = (setting = false) => {
    let languageEl = $('#gridHeader .s_multi_language_container');
    if (!init_fix_top_style || setting) {
        let multiLanguage = window._store.state.responsiveInfo.pattern.multiLanguage || {};
        let fts = digGet(multiLanguage, ['fts']);
        let { font, type } = fts;
        let cls = `.${FIX_TOP_GRID_HEADER_CLASS} .s_multi_language_container.${FIX_TOP_TEMP_CLASS} .s_multi_language_text, .multi_language_panel.fix_top_class .s_multi_language_normal_text`;
        let normalItemColorCls = `.${FIX_TOP_GRID_HEADER_CLASS} .s_multi_language_container.${FIX_TOP_TEMP_CLASS} .s_multi_language_normal_text, .multi_language_panel.fix_top_class .s_multi_language_normal_text `;
        let selectItemColorCls = `.${FIX_TOP_GRID_HEADER_CLASS} .s_multi_language_container.${FIX_TOP_TEMP_CLASS} .s_multi_language_active_text `;
        let styleCss = null;
        let useNew = false;
        if (type == 1) {
            styleCss = composeCssStyle(cls, font, { renderColor: false });
            useNew = true;
        } else {
            styleCss = fontSettingProperties.map((key) => {
                return {
                    cls,
                    key,
                    value: '',
                };
            });
            useNew = false;
        }
        styleCss.push(...fontColorStyle(normalItemColorCls, font.c, useNew));
        styleCss.push(...fontColorStyle(selectItemColorCls, font.sc, useNew));
        updateCssStyle(styleCss);
        if (setting) {
            sendFixTopFdp(FIX_TOP_STYLE_SETTING, '多语言导航');
        }
    }
    if (allowToAddClass(languageEl[0])) {
        languageEl.addClass(FIX_TOP_TEMP_CLASS);
    }
};

const resetFixTopLanguageStyle = () => {
    let languageEl = $('#gridHeader .s_multi_language_container');
    languageEl.removeClass(FIX_TOP_TEMP_CLASS);
};

const FixTopStyleHandler = {
    reset() {
        let fullScreen = getState('fullScreen');
        if (fullScreen === 0 && getScrollTop() > 10) {
            return;
        }

        resetFixTopWebTitleStyle();
        resetFixTopTextModuleStyle();
        resetFixTopNavStyle();
        resetFixTopLanguageStyle();
    },
    update() {
        updateFixTopWebTitleStyle();
        updateFixTopTextModuleStyle();
        updateFixTopNavStyle();
        updateFixTopLanguageStyle();
        init_fix_top_style = true;
    },
};

// 顶部样式更新
const updateFixTopStyle = () => {
    let scroH = getScrollTop(); //滚动高度
    if (scroH < 10) {
        // 滚动到顶部，切换回旧样式
        FixTopStyleHandler.reset();
        window._store.commit('header/setScrollToTop', true);
    } else {
        // 开始滚动，切换为固定样式，若固定样式有修改的话
        FixTopStyleHandler.update();
        window._store.commit('header/setScrollToTop', false);
    }
};

const updateFullScreenFixTopStyle = (curIndex = 0) => {
    if (curIndex == 0) {
        FixTopStyleHandler.reset();
        window._store.commit('header/setScrollToTop', true);
    } else {
        FixTopStyleHandler.update();
        window._store.commit('header/setScrollToTop', false);
    }
};

// 固定顶部绑定滚动事件，触发顶部样式更新
const bindScrollForFixTopStyle = () => {
    setTimeout(() => {
        updateFixTopStyle();
    }, 200);
    $(document).off('scroll.fixTopStyle').on('scroll.fixTopStyle', debounce(updateFixTopStyle, 50));
};

// 判断是否可添加固定顶部类型
const canAddFixTopClass = (type, moduleId = 0) => {
    let canAddClass = false;
    if (window._store.state.manageMode) {
        // 判断是否固定
        let isFixed = currentPageIsFixed();
        let isTop = window._store.state.header.scrollToTop;
        let isNavRow = false;
        // 判断是否只固定导航该行
        if (window._store.state.header.isOpenFixedHeight) {
            let rowId = 0;
            if (type == 'title') {
                let titleEl = $('#gridHeader .jz_website_title');
                rowId = findParentRowId(titleEl[0]);
            } else if (type == 'language') {
                let languageEl = $('#gridHeader .s_multi_language_container');
                rowId = findParentRowId(languageEl[0]);
            } else if (type == 'module') {
                let moduleEl = $(`#module${moduleId} .module_text_content`);
                rowId = findParentRowId(moduleEl[0]);
            } else if (type == 'nav') {
                isNavRow = true;
            }
            if (isElInNavRow(rowId)) {
                isNavRow = true;
            }
        }
        if (isFixed && !isTop) {
            canAddClass = window._store.state.header.isOpenFixedHeight ? isNavRow : true;
        }
    }
    return canAddClass;
};

const typeIsInNavRow = (type, moduleId) => {
    let rowId = 0;
    let isNavRow = false;
    if (window._store.state.header.isOpenFixedHeight) {
        if (type == 'title') {
            let titleEl = $('#gridHeader .jz_website_title');
            rowId = findParentRowId(titleEl[0]);
        } else if (type == 'language') {
            let languageEl = $('#gridHeader .s_multi_language_container');
            rowId = findParentRowId(languageEl[0]);
        } else if (type == 'module') {
            let moduleEl = $(`#module${moduleId} .module_text_content`);
            rowId = findParentRowId(moduleEl[0]);
        } else if (type == 'nav') {
            isNavRow = true;
        }
        if (isElInNavRow(rowId)) {
            isNavRow = true;
        }
    } else {
        return true;
    }
    return isNavRow;
};

const bindMouseEnterHeaderEvent = () => {
    const handler = throttle(function (e) {
        const el = document.querySelector('#gridHeader .g_header_content');
        if (!el) return;
        const headerElRect = el.getBoundingClientRect();
        const elInHeader = $(e.target).parents('#gridHeader').length > 0 === true;
        let enter =
            e.clientX >= headerElRect.left &&
            e.clientX <= headerElRect.right &&
            e.clientY >= headerElRect.top &&
            e.clientY <= headerElRect.bottom;

        enter = enter || elInHeader;
        const isFixed = $('#gridHeader').hasClass('g_header__fixed');

        if (enter === true) {
            mouseInHeader = true;
            FixTopStyleHandler.update();
        }
        if (enter === false && isFixed === false) {
            mouseInHeader = false;
            FixTopStyleHandler.reset();
        }
    }, 50 /** default */);

    $(document).off('mousemove.fixTopStyle').on('mousemove.fixTopStyle', handler);
};
const unbindMouseEnterHeaderEvent = () => {
    $(document).off('mousemove.fixTopStyle');
};

export {
    moduleInHeader,
    getComputedTextlinearGradientColor,
    bindScrollForFixTopStyle,
    updateFixTopWebTitleStyle,
    updateFixTopTextModuleById,
    updateFixTopLanguageStyle,
    updateFixTopNavStyle,
    sendFixTopFdp,
    currentPageIsFixed,
    updateFullScreenFixTopStyle,
    canAddFixTopClass,
    findParentRowId,
    isElInNavRow,
    typeIsInNavRow,
    FIX_TOP_GRID_HEADER_CLASS,
    FIX_TOP_HEADER_SETTING,
    FIX_TOP_TEMP_CLASS,
    bindMouseEnterHeaderEvent,
    unbindMouseEnterHeaderEvent,
};
