import { mapState } from 'vuex';
import { Comm, encodeHtmlStr } from '@/components/utils.js';
import { bindUeSlider } from '@/components/modules/shared/ue/index.js';
import { GLOBAL_FONT_FAMILY_NAME_CSS_VALUE } from '@/store/modules/manage/modules/globalFont';

export const photoGroupInfoMixin = {
    computed: {
        photoGroupInfo() {
            return this.module.photoGroupInfo || {};
        },
        title() {
            return encodeHtmlStr(this.photoGroupInfo.name) || '';
        },
        ueContent() {
            return this.photoGroupInfo.groupDetail || '';
        },
    },
};

export const photoGroupDetailMixin = {
    computed: {
        ...mapState(['device']),
        LS() {
            return this.$store.state.LS;
        },
        shareList() {
            return this.module.prop3 || [];
        },
        shareParamList() {
            return this.module.shareParamList || [];
        },
        photoList() {
            return this.module.photoList.map((item) => {
                return {
                    desc: encodeHtmlStr(item.basic),
                    path: item.picPath,
                    url: item.url || '',
                    name: item.name || '',
                    id: item.id,
                };
            });
        },
        prefix() {
            return this.LS.preGroup + ':';
        },
        suffix() {
            return this.LS.nextGroup + ':';
        },
        share() {
            return this.LS.share;
        },
        nextPhotoGroupInfo() {
            return this.module.nextPhotoGroupInfo;
        },
        prePhotoGroupInfo() {
            return this.module.prePhotoGroupInfo;
        },
        emptyPrePhotoGroup() {
            return this.prePhotoGroupInfo == null;
        },
        emptyNextPhotoGroup() {
            return this.nextPhotoGroupInfo == null;
        },
        preName() {
            return encodeHtmlStr(this.prePhotoGroupInfo.name);
        },
        preUrl() {
            return this.prePhotoGroupInfo.url;
        },
        nextName() {
            return encodeHtmlStr(this.nextPhotoGroupInfo.name);
        },
        nextUrl() {
            return this.nextPhotoGroupInfo.url;
        },
        flagName: Comm.bindFlag(0x1),
        flagDetailClose: Comm.bindFlag(0x2),
        flagPrevNext: Comm.bindFlag(0x4),
        flagDescClose: Comm.bindFlag(0x8),
        flagShare: Comm.bindFlag(0x10),
        descShow() {
            return !this.flagDescClose;
        },
        titleStyle() {
            let pcFont = this.module.privatePattern.pc.ptf,
                alignDict = ['left', 'center', 'right', 'justify'],
                font = pcFont,
                fontStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s + 'px',
                    fontFamily: font.f,
                    color: font.c,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                    textAlign: alignDict[font.ta],
                };

                if (font.ugf === 1) {
                    fontStyle.fontFamily = GLOBAL_FONT_FAMILY_NAME_CSS_VALUE;
                }
            }

            Comm.extend(style, fontStyle);
            return style;
        },
    },
    methods: {
        bindUeSlider(e) {
            bindUeSlider(e, '自适应-图册详情-ue');
        },
    },
};
