export const FORM_ITEM_TYPES = {
    TEXT: 0,
    TEXT_AREA: 1,
    RADIO: 2,
    CHECKBOX: 3,
    SELECT: 4,
    DESCRIPTION: 5,
    DATE: 6,
    FILE_UPLOAD: 7,
    PHONE: 8,
    MAIL: 9,
    ID_CARD: 10,
    LOCATION: 11,
    MULTI_LEVEL_DROPDOWN: 12,
    NUMBER: 13,
};

/**
 *
 * Transformers
 *
 */
const defaultTransform = (value) => value;

const transformCheckboxValue = (value) => {
    return value === '' ? [] : value.split('\n');
};

/**
 *
 * @example ch1363240xxx | 16332232xxx | ch
 * @param {string} value
 */
const transformPhoneValue = (value = '') => {
    if (value !== '') {
        const [, areaCode = '', phoneNumber = ''] = value.match(/([a-zA-Z]+)?([\d]+)?/);
        return {
            areaCode,
            phoneNumber,
        };
    } else {
        return {
            phoneNumber: '',
            areaCore: '',
        };
    }
};

const transformDateValue = (value, context) => {
    const values = value.split('至');
    const banPassDate = (context.formItem.dateSetting?.banPassDate ?? 0) == 1;

    if (values.length >= 1) {
        // 从 itemType6.vue 逻辑抽取出来
        let nowDate = new Date(1900, 0, 1);
        if (banPassDate) {
            const now = new Date();
            nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        }
        return {
            startTime: new Date(values[0]) >= nowDate ? values[0] : '',
            endTime: new Date(values[1]) >= nowDate ? values[1] : '',
        };
    } else {
        return {
            startTime: '',
            endTime: '',
        };
    }
};

const transformMultiLevelDropdownValue = (value) => {
    const [first = '', second = '', third = '', fourth = ''] = value.split('-');
    return {
        firstLevel: {
            val: first,
            disabled: false,
        },
        secondLevel: {
            val: second,
            disabled: second === '' ? true : false,
        },
        thirdLevel: {
            val: third,
            disabled: third === '' ? true : false,
        },
        fourthLevel: {
            val: fourth,
            disabled: fourth === '' ? true : false,
        },
    };
};

const transformFileUploadValue = (value, context) => {
    if (!Array.isArray(value) || (Array.isArray(value) && value.length === 0)) {
        return '';
    }

    const previewFiles = (context?.files ?? []).map((file) => ({
        src: file.downloadUrl,
        key: file.id,
        fileId: file.id,
        title: file.name,
    }));
    return previewFiles;
};

/**
 * @example 山西省大同市新荣区
 * @description 需要根据省份信息查找省ID,进而找到市和区的ID
 * @param {string} value
 */
export const transformLocationValue = async (value, context) => {
    if (!value || typeof window === 'undefined') {
        return {
            province: {
                id: -1,
                name: '',
            },
            city: {
                id: -1,
                name: '',
            },
            county: {
                id: -1,
                name: '',
            },
        };
    }

    const provinces = context.provinces;

    const province = provinces.find((province) => {
        return value.includes(province.name);
    });

    const cities = await window.site_cityUtil.getCities(province.id);
    const city = cities.find((city) => {
        return value.includes(city.name);
    });

    const counties = await window.site_cityUtil.getCounty(city.id);
    const county = counties.find((county) => {
        return value.includes(county.name);
    });

    return {
        province: {
            id: province.id,
            name: province.name,
        },
        city: {
            id: city.id,
            name: city.name,
        },
        county: {
            id: county.id,
            name: county.nme,
        },
    };
};

const TransformMapping = new Map();
TransformMapping.set(-1, defaultTransform);
TransformMapping.set(FORM_ITEM_TYPES.TEXT, defaultTransform);
TransformMapping.set(FORM_ITEM_TYPES.TEXT_AREA, defaultTransform);
TransformMapping.set(FORM_ITEM_TYPES.RADIO, defaultTransform);
TransformMapping.set(FORM_ITEM_TYPES.SELECT, defaultTransform);
TransformMapping.set(FORM_ITEM_TYPES.DESCRIPTION, defaultTransform);
TransformMapping.set(FORM_ITEM_TYPES.MAIL, defaultTransform);
TransformMapping.set(FORM_ITEM_TYPES.ID_CARD, defaultTransform);
TransformMapping.set(FORM_ITEM_TYPES.NUMBER, defaultTransform);
TransformMapping.set(FORM_ITEM_TYPES.CHECKBOX, transformCheckboxValue);
TransformMapping.set(FORM_ITEM_TYPES.PHONE, transformPhoneValue);
TransformMapping.set(FORM_ITEM_TYPES.DATE, transformDateValue);
TransformMapping.set(FORM_ITEM_TYPES.MULTI_LEVEL_DROPDOWN, transformMultiLevelDropdownValue);
TransformMapping.set(FORM_ITEM_TYPES.FILE_UPLOAD, transformFileUploadValue);

/**
 *
 * 表单提交记录存的值与UI模型的转换
 *
 * @param {string} value  - 表单提交记录值
 * @param {Object} context - formItem Object
 */
export const transformToUIModel = (value, context = {}) => {
    const { type } = context?.formItem ?? {}; // 表单项类型

    if (!type) {
        return value;
    }

    const transform = TransformMapping.get(type);
    return transform ? transform(value, context) : undefined;
};
