/**
 * 移除url hash和一部分get参数到cookie
 */

const argCookieKey = '_reqArgs';
const getIsOpenRemoveUrlArg = function () {
    return window._store.state.openRemoveUrlArgs;
};

const getIsSetReqHttpOnlyCookie = function () {
    return window._store.state.setReqHttpOnlyCookie;
};

// 处理jsp吐出的onclick="JZ.setUrlArgToCookie(this, 'testArgsValue');"的跳转
const setUrlArgToCookie = function (element, args) {
    if (typeof event !== 'undefined') {
        const path = event.path || [];
        const closestA = path.find((el) => el.tagName === 'A' && el.getAttribute('href'));
        const elementIndex = path.indexOf(element);
        const closestAIndex = path.indexOf(closestA);
        if (closestAIndex !== -1 && closestAIndex < elementIndex) {
            const cloneA = closestA.cloneNode();
            cloneA.click();
            return;
        }
    }
    const href = element.href || '';
    if (!href) return;
    JZ.handleSetUrlArgToCookie(args)
        .then(() => {
            let cloneA = element.cloneNode();
            cloneA.setAttribute('onclick', '');
            // ios target为_blank模拟点击，不跳转
            if (jzUtils.browser.isIos()) {
                cloneA.setAttribute('target', '_self');
            }
            //window 7 要插入到节点中才能触发click
            if (jzUtils.browser.isIE()) {
                let $cloneA = $(cloneA);
                $cloneA.css({ width: 1, height: 1 }).appendTo($('body'))[0].click();
                $cloneA.remove();
                $cloneA = null;
            } else {
                cloneA.click();
            }
            cloneA = null;
        })
        .catch((err) => console.error(err));
    return false;
};

// 设置urlArg到cookie的动作
const handleSetUrlArgToCookie = function (args) {
    return new Promise((resolve, reject) => {
        try {
            if (typeof args === 'object') {
                args = JSON.stringify(args);
            }
        } catch (err) {
            console.error(err);
            resolve();
        }
        if (getIsSetReqHttpOnlyCookie()) {
            $.ajax({
                url: '/ajax/setCookie_h.jsp',
                type: 'post',
                // qq浏览器非同步请求，会拦截window.open弹窗，这个setCookie_h的请求正常情况是非常快，不会阻塞很久
                async: !jzUtils.browser.isQQ(),
                data: {
                    cmd: 'setWafCk_setReqArgsCookie',
                    _reqArgs: args,
                },
            })
                .done(() => {
                    resolve();
                })
                .fail(reject);
        } else {
            $.cookie(argCookieKey, args, { path: '/' });
            resolve();
        }
    });
};

const setUrlArgToCookieAndJump = function (url, args, options = {}) {
    if (getIsOpenRemoveUrlArg() && args) {
        handleSetUrlArgToCookie(args).then(() => {
            if (options.blank) {
                if (jzUtils.browser.isIos()) {
                    window.location.href = url;
                } else {
                    window.open(url);
                }
            } else {
                window.location.href = url;
            }
        });
    } else {
        if (options.blank) {
            if (jzUtils.browser.isIos()) {
                window.location.href = url;
            } else {
                window.open(url);
            }
        } else {
            window.location.href = url;
        }
    }
};

const createUrlArgsOnclickStr = function (args, hasOnclickStr = true) {
    if (!args) return '';
    const argsStr = typeof args === 'string' ? args : JSON.stringify(args);
    const onclickStr = `JZ.setUrlArgToCookie(this, ${argsStr}); return false;`;
    if (getIsOpenRemoveUrlArg()) {
        return hasOnclickStr ? `onclick='${onclickStr}'` : onclickStr;
    }
    return '';
};

const getUrlArgInCookie = function () {
    let hrefArg = '';
    if (typeof Fai !== 'undefined') {
        hrefArg = decodeURIComponent(Fai.getUrlParam(window.location.href, '_reqArgs') || '');
    }
    return $.cookie(argCookieKey) || window._store.state.reqArgs || hrefArg || '';
};

const getUrlHashInCookie = function () {
    const cookie = getUrlArgInCookie();
    if (!cookie) return '';
    try {
        const args = JSON.parse(cookie) || {};
        return args.anchor || '';
    } catch (err) {
        console.log(err, `hash in cookie is => ${cookie}`);
        return '';
    }
};

const removeUrlArgInCookie = function () {
    return new Promise((resolve) => {
        if (getIsSetReqHttpOnlyCookie()) {
            // handleSetUrlArgToCookie这个方法的接口跟这个一致，不过那个还没啥办法改，这个也不改。
            $.ajax({
                url: '/ajax/setCookie_h.jsp',
                type: 'post',
                async: false,
                data: {
                    cmd: 'setWafCk_setReqArgsCookie',
                    _reqArgs: '',
                },
                success: () => {
                    resolve();
                },
            });
        } else {
            $.cookie(argCookieKey, null, { path: '/' });
            resolve();
        }
    });
};

const setHrefEventHasReqArgs = function (dom) {
    if (!getIsOpenRemoveUrlArg()) return;
    if (!dom) return;
    let $dom = dom;
    try {
        if (dom instanceof Node || dom instanceof NodeList) {
            $dom = $(dom);
        }
    } catch (err) {
        console.error(err);
    }
    if ($dom.length === 0) return;
    if ($dom.prop('setReqArgsClick') == '1') {
        return;
    }
    $dom.prop('setReqArgsClick', '1');
    $dom.off('click.setReqArgs').on('click.setReqArgs', 'a', function (event) {
        const $a = $(this);
        const _reqArgs = $a.attr('_reqargs');
        if (!_reqArgs) return;
        event.preventDefault();
        setUrlArgToCookie($a[0], _reqArgs);
    });
};
export {
    getIsOpenRemoveUrlArg,
    getIsSetReqHttpOnlyCookie,
    setUrlArgToCookie,
    handleSetUrlArgToCookie,
    setUrlArgToCookieAndJump,
    createUrlArgsOnclickStr,
    getUrlArgInCookie,
    getUrlHashInCookie,
    removeUrlArgInCookie,
    setHrefEventHasReqArgs,
};
