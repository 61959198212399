
export default {
    props: {
        price: {
            type: [Number, String],
            default: '',
        },
        currency: {
            type: String,
            default: '',
        },
        currencyPositionRight: {
            type: Boolean,
            default: false,
        },
        toFixed: {
            type: Number,
            default: -1,
        },
    },
    render() {
        const priceText = this.toFixed > -1 ? Number(this.price).toFixed(this.toFixed) : this.price;
        const priceEl = <span class="pay_product_price_value">{priceText}</span>;
        const coinEl = <span class="pay_product_price_coin">{this.currency}</span>;
        return (
            <div class="pay_product_price_item">
                {this.currencyPositionRight ? [priceEl, coinEl] : [coinEl, priceEl]}
            </div>
        );
    },
};
