import { watch } from 'vue';
import { debounce } from '@jz/utils';

const STORAGE_KEY = 'placeOrderData';

export const useDataStorage = (module, otherData = {}) => {
    if (typeof localStorage === 'undefined') {
        return;
    }

    const storageData = localStorage.getItem(STORAGE_KEY);

    if (storageData) {
        try {
            const { propList } = JSON.parse(storageData);
            module.propList.forEach((prop) => {
                const storageProp = propList.find((p) => p.id === prop.id);
                if (storageProp) {
                    prop.value = storageProp.value;
                }
            });
        } catch (error) {
            console.error('Failed to parse storage data', error);
            localStorage.removeItem(STORAGE_KEY);
        }
    }

    const setStorage = debounce(() => {
        const propList = module.propList.map((prop) => {
            return {
                id: prop.id,
                type: prop.type,
                value: prop.value,
            };
        });
        localStorage.setItem(STORAGE_KEY, JSON.stringify({ propList, memberMsg: otherData.memberMsg.value }));
    }, 300);

    watch(
        () => {
            return [module.propList, otherData.memberMsg];
        },
        () => {
            setStorage();
        },
        { deep: true }
    );
};
