var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mobi-view" },
    [
      !_vm.flagName
        ? _c("h1", {
            staticClass: "title",
            style: _vm.titleStyle,
            domProps: { innerHTML: _vm._s(_vm.title) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("carousel-component", {
        staticClass: "carousel-photo-list",
        attrs: {
          "photo-list": _vm.photoList,
          mobile: _vm.mobile,
          "des-show": _vm.descShow,
          "module-id": _vm.id,
        },
      }),
      _vm._v(" "),
      !_vm.flagDetailClose
        ? _c("div", {
            staticClass: "content",
            domProps: { innerHTML: _vm._s(_vm.ueContent) },
            on: {
              click: function ($event) {
                return _vm.bindUeSlider($event)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "footer photo-group-mobi-footer" }, [
        !_vm.flagPrevNext
          ? _c("div", { staticClass: "group-list" }, [
              !_vm.emptyPrePhotoGroup
                ? _c(
                    "a",
                    {
                      staticClass: "group pre-group",
                      attrs: { href: _vm.preUrl },
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.prefix) },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "group-name",
                        domProps: { innerHTML: _vm._s(_vm.preName) },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.emptyNextPhotoGroup
                ? _c(
                    "a",
                    {
                      staticClass: "group next-group",
                      attrs: { href: _vm.nextUrl },
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.suffix) },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "group-name",
                        domProps: { innerHTML: _vm._s(_vm.nextName) },
                      }),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.flagShare
          ? _c(
              "div",
              {
                staticClass: "mobile-share jz_share_info",
                attrs: { id: _vm.sharePanelId },
                on: { click: _vm.showSharePanel },
              },
              [
                _c("div", { staticClass: "share-container" }, [
                  _c("svg", { attrs: { width: "16px", height: "16px" } }, [
                    _c("use", {
                      attrs: { "xlink:href": "#icon_photo_group_detail_share" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "news_share_text" }, [
                    _vm._v(_vm._s(_vm.share)),
                  ]),
                ]),
                _vm._v(" "),
                _c("jz-share-ssr-compon", {
                  attrs: {
                    list: _vm.shareList,
                    "share-param-list": _vm.shareParamList,
                    source: "图册目录详情",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }