import { getFontList, formatFontList } from '@/manage/designer/initContext/fonts.js';

export default {
    namespaced: true,
    state: () => ({
        fontList: [],
        fontListInitialized: false,
    }),
    actions: {
        async initFontList({ commit }) {
            const list = await getFontList();
            commit('setFontList', list);
        },
    },
    mutations: {
        setFontList(state, fontList) {
            if (state.fontListInitialized) return;
            state.fontList = fontList;
            state.fontListInitialized = true;
        },
    },
    getters: {
        formattedFontList(state) {
            return formatFontList(state.fontList);
        },
        isOpenGlobalFont(_, __, rootState) {
            return rootState.responsiveInfo.pattern.globalFont.o === 1;
        },
    },
};

export const GLOBAL_FONT_FAMILY_NAME = 'globalFont';

export const GLOBAL_TITLE_FONT_FAMILY_NAME = 'globalTitleFont';

export const GLOBAL_FONT_FAMILY_NAME_CSS_VALUE = 'var(--global-font-family)';

export const defaultFont = {
    id: -10000,
    name: '默认字体',
    'font-family': GLOBAL_FONT_FAMILY_NAME_CSS_VALUE,
    // 隐藏可商用提示
    hideCommercial: true,
};
