import { computed } from 'vue';
import { PayProduct } from '@/services';

export const usePayUnit = (module) => {
    const unitInfo = computed(() => {
        let currencySymbol = module.currencySymbol ?? '';

        if (
            [
                PayProduct.payProductStatus.PAY_SUCCESS,
                PayProduct.payProductStatus.WAIT_SEND,
                PayProduct.payProductStatus.SENT,
                PayProduct.payProductStatus.FINISHED,
            ].includes(module.orderInfo?.status)
        ) {
            currencySymbol = module.orderInfo.currencySymbol;
        }

        return {
            siteCurrencyRight: module.siteCurrencyRight ?? false,
            currencySymbol,
        };
    });

    const addUnit = (price) => {
        const { siteCurrencyRight, currencySymbol } = unitInfo.value;
        if (siteCurrencyRight && currencySymbol != '￥') {
            return `${price}${currencySymbol}`;
        }
        return `${currencySymbol}${price}`;
    };

    return {
        unitInfo,
        addUnit,
    };
};
