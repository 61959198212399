
import { mapGetters, mapState } from 'vuex';

import { Comm } from '../../utils';
const { bindFlag, getStyleDataByDevice, getComputedTextColor, extend, getWrapStyle, createUrlArgsOnclickStr } = Comm;
import { openSiteForm } from '@shared/visitor/form/index.js';
import { productList as productListModule } from '@shared/visitor/productList/index.js';
import { animationMixin } from '../animationMixin';
import { photoSettingMixin } from '../photoSettingMixin';
import { paginationMixin } from '../paginationMixin';
import { setQueryString } from '../../utils';
import { encodeHtmlStr } from '../../utils';
import { getProductList } from './utils';

const manageUse = {
    components: {},
};
import PaginationComponent from '@/components/modules/common/pagination/index.vue';
import { ImageComponent } from '@jz/biz-shared';
import { PayKnowProduct, PayProduct } from '@/services';
import { warningMessage } from '@/site/shared/dialog/index.js';
import { createLoginPopup } from '@/site/shared/loginPopup/index';
import { GLOBAL_FONT_FAMILY_NAME_CSS_VALUE } from '@/store/modules/manage/modules/globalFont';
const textAlignMap = {
    0: '',
    1: 'left',
    2: 'right',
    3: 'center',
};
export default {
    name: 'ProductList',
    components: {
        ...manageUse.components,
        PaginationComponent,
        ImageComponent,
    },
    mixins: [animationMixin, photoSettingMixin, paginationMixin],
    style: 16,
    props: ['id', 'module'],
    data() {
        var data = {
            hideList: this.module.prop1.h,
            choiceCurrencyVal: this.module.choiceCurrencyVal,
            controller: {},
            currentPage: 1,
            firstInit: false,
            getLoading: false,
            sortTypes: ['desc', 'asc'],
        };

        return data;
    },

    computed: {
        ...mapState(['manageMode', 'colId', 'memberLogin']),
        ...mapState('manage/material', ['materialFactory']),
        ...mapGetters(['hashRemoved']),

        themeColor() {
            return this.$store.state.responsiveInfo.themeColor;
        },

        showList() {
            return this.module.prop1.s;
        },

        other() {
            return this.module.blob0;
        },

        productList: {
            get() {
                return this.module.productLists || [];
            },
            set(value) {
                this.module.productLists = value;
            },
        },

        productTotalSite() {
            return this.module.productTotalSize;
        },

        flagSelectProduct: bindFlag(0x1),
        // 是否需要分页
        flagPagination: bindFlag(0x4),
        flagIsHideFormBtn: bindFlag(0x8),
        flagIsCustomBtnText: bindFlag(0x10),
        hidePropNameSetting: bindFlag(0x20),
        showPagination() {
            return this.flagPagination && module.prop0 != 1;
        },

        priceStyle() {
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.ppf,
                mobiData: this.mobiPrivatePattern.ppf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },

        marketPriceStyle() {
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.pmpf,
                mobiData: this.mobiPrivatePattern.pmpf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline line-through' : 'line-through',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },

        prizeResetStyle() {
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.ppf,
                mobiData: this.mobiPrivatePattern.ppf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            const style = {};
            style.fontSize = font.y === 0 ? '' : 'inherit';

            if (this.isMobi && this.mobiPrivatePattern.ppf.y === 0) {
                style.fontSize = '';
            }

            return style;
        },

        nameStyle() {
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.pnf,
                mobiData: this.mobiPrivatePattern.pnf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                if (font.ugf === 1) {
                    fontStyle.fontFamily = GLOBAL_FONT_FAMILY_NAME_CSS_VALUE;
                }
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            const wrapData = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.pnl,
                mobiData: this.mobiPrivatePattern.pnl,
                isMobi: this.isMobi,
            });
            extend(style, getWrapStyle(wrapData));
            return style;
        },

        propStyle() {
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.paf,
                mobiData: this.mobiPrivatePattern.paf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },

        mobiPrivatePattern() {
            return this.module.privatePattern.mobi;
        },

        paginationPageSize() {
            return this.module.prop2;
        },

        paginationStyleIndex() {
            return this.module.prop4;
        },

        paginationPageCount() {
            const totalSize = this.module.productTotalSize;
            return totalSize % this.paginationPageSize == 0
                ? totalSize / this.paginationPageSize
                : Math.ceil(totalSize / this.paginationPageSize);
        },

        productStyle() {
            if (!this.manageMode) {
                return;
            }
            var style = {};

            if (!this.isMobi) {
                var rate = '24.99%',
                    defaultMargin = ''; // 解决edge下 一行不能显示4个的 计算问题

                defaultMargin = '20px';

                if (this.module.prop0 === 3) {
                    defaultMargin = '22px';
                }

                rate = this.module.prop0 != 2 ? '24.99%' : '100%';

                if (this.model.pmt == 1) {
                    style.margin = this.model.pmv / 2 + 'px ' + this.model.pmh / 2 + 'px';
                    defaultMargin = this.model.pmh + 'px';
                }

                if (this.module.prop0 != 2) {
                    if (this.model.rlt == 1) {
                        rate = Math.floor((100 * 100) / this.model.rl) / 100 + '%';
                        if (rate === '25%') rate = '24.99%';

                        if (this.model.pmt == 1) {
                            style.width = 'calc(' + rate - (this.model.pmv + 'px') + ')';
                        }
                    }
                }

                style.width = 'calc(' + rate + ' - ' + defaultMargin + ')';
            }

            if (this.isMobi) {
                if (this.module.prop0 === 0) {
                    // 样式一支持单独设置手机每行个数
                    if (this.model.rlt === 1) {
                        style.width = `${100 / this.model.rl - 3.6}%`;
                    }
                }

                if (this.module.prop0 !== 3) {
                    if (this.model.pmt == 1) {
                        rate = '50%';
                        defaultMargin = ''; // 解决edge下 一行不能显示4个的 计算问题

                        defaultMargin = '20px';

                        if (this.module.prop0 === 3) {
                            defaultMargin = '22px';
                        }

                        rate = this.module.prop0 != 2 ? '50%' : '100%';

                        if (this.module.prop0 == 0) {
                            if (this.model.rlt == 1) {
                                rate = `${100 / this.model.rl - 3.6}%`;
                                if (rate === '25%') rate = '24.99%';
                            }
                        }

                        style.margin = this.model.pmv / 2 + 'px ' + this.model.pmh / 2 + 'px';
                        defaultMargin = this.model.pmh + 'px';
                        style.width = 'calc(' + rate + ' - ' + defaultMargin + ')';
                    }
                }
            }

            return style;
        },

        productsInRow() {
            let len = 4; // 默认4个

            if (this.model.rlt == 1) {
                len = this.model.rl;
            }

            return len;
        },

        productImgSize() {
            var style = {};

            if (this.module.prop0 != 2) {
                if (this.model.pht == 1) {
                    // 图片高度
                    style.paddingBottom = Math.floor(this.model.ph * 10000) / 100 + '%';
                } else if (this.isMobi && this.model.pht == 0 && this.pcPrivatePattern.pht == 1) {
                    style['padding-bottom'] = Math.floor(this.pcPrivatePattern.ph * 10000) / 100 + '%';
                }
            } else {
                if (this.model.pst == 1) {
                    // 样式三 图片高度
                    style.width = Math.floor(this.model.psw * 10000) / 100 + '%';
                    style.paddingBottom = Math.floor(this.model.psh * 10000) / 100 + '%';
                }
            }

            return style;
        },

        productPropSize() {
            var style = {};

            if (this.module.prop0 == 2) {
                if (this.module.privatePattern[this.device].pst == 1) {
                    style.width =
                        'calc( 100% - ' +
                        Math.floor(this.module.privatePattern[this.device].psw * 10000) / 100 +
                        '% - 20px )';
                }
            }

            return style;
        },

        propAlign() {
            let align = this.module.privatePattern[this.device].align;

            if (this.isMobi && align === 0) {
                align = this.module.privatePattern.pc.align;
            }

            return {
                textAlign: textAlignMap[align],
            };
        },

        imgJump() {
            return this.flagIsBlank ? '_self' : '_blank';
        },

        moduleClasses() {
            const classes = {},
                style = this.module.prop0,
                pcPrivatePattern = this.module.privatePattern['pc'];
            classes['jz_product_list_' + style] = true;

            if (style == 0 || style == 1) {
                if (pcPrivatePattern.rlt === 1 && (pcPrivatePattern.rl === 1 || pcPrivatePattern.rl === 3)) {
                    classes['jz_product_list_mobi_full'] = true;
                }
            }

            return classes;
        },

        // 分类列表，0为未分类
        gids() {
            return this.module.prop3.gids || [];
        },

        selectedLibId() {
            return this.module.blob0.selectLibId;
        },

        flagIsBlank: bindFlag(0x2),

        isNonePicStyle() {
            return this.module.prop0 === 3; // 3为产品无图样式
        },

        picItemStyle() {
            var style = {};
            switch (this.curPpst) {
                case 1:
                    style['object-fit'] = 'cover';
                    style['object-position'] = 'center';
                    break;
                case 2:
                    style['object-fit'] = 'none';
                    style['object-position'] = 'center';
                    break;
                case 3:
                    style['object-fit'] = 'contain';
                    style['object-position'] = 'center';
                    break;
            }
            return style;
        },

        propList() {
            return this.module.finalShowPropListByLib;
        },

        exhibitionShowProductsClass() {
            var tmpClass = [];
            this.isNonePicStyle && tmpClass.push('exhibition_show_products_nopic');

            return tmpClass;
        },

        exhibitionShowProductClass() {
            var tmpClass = [];
            this.isNonePicStyle && tmpClass.push('exhibition_show_product_nopic');
            return tmpClass;
        },

        sortKey() {
            try {
                return this.module.blob0.customSort.sortKey || 'name';
            } catch (error) {
                return 'name';
            }
        },

        sortType() {
            try {
                return this.sortTypes[this.module.blob0.customSort.sortType || 0];
            } catch (error) {
                return this.sortTypes[0];
            }
        },

        allProductVisible() {
            return this.module.blob0.av === 1;
        },

        visibleProductCount() {
            return this.module.prop6;
        },
        siteCurrencyRight() {
            return this.module.siteCurrencyRight || false;
        },
        isRMB() {
            return this.module.choiceCurrencyVal == '￥';
        },
        currencyToRight() {
            return !this.isRMB && this.siteCurrencyRight;
        },
        showShoppingCartForBtn() {
            let globalOpenShoppingCart = this.module.openShoppingCart;
            let settingShowShoppingCart = this.isNonePicStyle || (!this.isNonePicStyle && this.isMobi);
            if (globalOpenShoppingCart && settingShowShoppingCart && this.openECommerce) {
                let openShoppingCart = this.module.blob0.showCartBtn;
                return openShoppingCart == null ? globalOpenShoppingCart : openShoppingCart;
            } else {
                return false;
            }
        },
        showShoppingCartForImg() {
            let globalOpenShoppingCart = this.module.openShoppingCart;
            let settingShowShoppingCart = !this.isNonePicStyle && !this.isMobi;
            if (globalOpenShoppingCart && settingShowShoppingCart && !this.isMagnifier && this.openECommerce) {
                let openShoppingCart = this.module.blob0.showCartBtn;
                return openShoppingCart == null ? globalOpenShoppingCart : openShoppingCart;
            } else {
                return false;
            }
        },
        showShoppingCartForEffect() {
            let globalOpenShoppingCart = this.module.openShoppingCart;
            let settingShowShoppingCart = !this.isNonePicStyle && !this.isMobi;
            if (globalOpenShoppingCart && settingShowShoppingCart) {
                let openShoppingCart = this.module.blob0.showCartBtn;
                return openShoppingCart == null ? globalOpenShoppingCart : openShoppingCart;
            } else {
                return false;
            }
        },
        isMagnifier() {
            // 是否放大镜
            return this.module.commProp.pa.mt === 1;
        },
        openECommerce() {
            return this.module.commerceSettings.openECommerce || false;
        },
    },

    created() {
        this.watchPropsAndFetchData();
    },

    mounted() {
        this.controller = new productListModule(this.id);
    },

    methods: {
        getPayKnowTag(item) {
            return PayKnowProduct.getProductPayKnowTypeText(item);
        },
        getIsShowPayKnowTag(item) {
            return this.module.openKnowPayment && PayKnowProduct.isPayKnowProduct(item) && this.pcPrivatePattern.ct;
        },
        isPayProduct(item) {
            return PayProduct.isPayProduct(item);
        },
        getFormBtnText(item) {
            const { blob0, globalProductFormText } = this.module;
            const knowButtonText = this.module.knowButtonText || '购买内容';

            let defaultText = globalProductFormText;
            if (PayKnowProduct.isPayKnowProduct(item)) {
                defaultText = knowButtonText;
            }
            if (PayProduct.isPayProduct(item)) {
                defaultText = this.module.commerceSettings.purchaseBtnText;
            }

            const customText = blob0.btnText === '' ? '请输入按钮文本' : blob0.btnText;
            return this.flagIsCustomBtnText ? customText : defaultText;
        },
        isShowBtn(item) {
            const isDefaultShow =
                this.module.openProductForm ||
                PayKnowProduct.isPayKnowProduct(item) ||
                PayProduct.isPayProduct(item) ||
                (this.showShoppingCartForBtn && PayProduct.isPayProduct(item));
            return isDefaultShow && !this.flagIsHideFormBtn && !this.isLinkProduct(item.productType);
        },
        showPayProductCartForImg(item) {
            return this.showShoppingCartForImg && PayProduct.isPayProduct(item) && !item.isOptionActive;
        },
        btnWrapClass(item) {
            let classlist = [];
            if (this.isNonePicStyle || (this.isMobi && this.showShoppingCartForBtn && PayProduct.isPayProduct(item))) {
                classlist.push('flex_btn_wrap');

                let align = this.module.privatePattern[this.device].align;

                if (this.isMobi && align === 0) {
                    align = this.module.privatePattern.pc.align;
                }

                switch (align) {
                    case 0:
                        break;
                    case 1:
                        classlist.push('flex_start');
                        break;
                    case 2:
                        classlist.push('flex_end');
                        break;
                    case 3:
                        classlist.push('flex_center');
                        break;
                }
            }
            return classlist;
        },
        addToCart(item, event) {
            if (this.manageMode) {
                warningMessage('管理态下不支持将产品加到购物车', true);
                return;
            }
            event?.stopPropagation();
            event?.preventDefault();
            if (!this.memberLogin) {
                createLoginPopup();
                return;
            }

            PayProduct.addToCart(item.id, this.module.shoppingCartUrl);
        },
        handleMouseenter(item) {
            this.manageMode && window._store.dispatch('manage/pauseDataWatch');
            this.$set(item, 'hovering', true);
        },
        handleMouseleave(item) {
            this.manageMode && window._store.dispatch('manage/pauseDataWatch');
            this.$set(item, 'hovering', false);
        },
        watchPropsAndFetchData() {
            if (!this.manageMode) {
                return;
            }
            this.$watch(
                () =>
                    JSON.stringify([
                        this.module.prop0,
                        this.flagSelectProduct,
                        this.flagPagination,
                        this.paginationStyleIndex,
                        this.module.prop5,
                        this.module.blob0.customSort.sortKey,
                        this.module.blob0.customSort.sortType,
                        this.module.prop3.gids,
                        this.allProductVisible,
                        this.visibleProductCount,
                        this.paginationPageSize,
                        this.module.blob0.selectLibId,
                    ]),
                () => {
                    this.resetPaginationList();
                    this.handlePaginationPageChange(1);
                }
            );
        },
        encodeHtmlStr,
        resetPaginationList() {
            this.currentPage = 1;
            this.removeHistoryCurrentPage();
        },

        getHoverStyle(item) {
            let style = {};
            const font = getStyleDataByDevice({
                pcData: this.pcPrivatePattern.pnf,
                mobiData: this.mobiPrivatePattern.pnf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });

            if (item.hovering) {
                if (font.y != 0) {
                    style = getComputedTextColor(font.hc || '#000');
                } else {
                    style.color = this.themeColor;
                }
            }

            if (this.isMobi) {
                return {};
            }

            return style;
        },

        setQueryString(url, key, val) {
            return setQueryString(url, key, val);
        },

        createOnclickStr(item) {
            return createUrlArgsOnclickStr(item.pdReqArgs, this.module._openRemoveUrlArgs, this.hashRemoved);
        },

        /**
         * @function name - isPriceVisible
         * @description 是否显示价格
         * @author mikey.chuhui
         * @date 2020-08-03
         * @param [productItem ={}] {Object}
         * @returns {boolean}
         */
        isPriceVisible(productItem = {}) {
            const showListByLib = this.showList[`lib_${productItem.lid}`];
            const propListByLib = this.propList[`lib_${productItem.lid}`]; // 判断prop中价格allowed属性，限制访客查看权限

            let notAllowed = false;
            propListByLib.some((item) => {
                if (item.fieldKey == 'mallPrice' && !item.allowed) {
                    notAllowed = true;
                    return true;
                }
            });

            if (notAllowed) {
                return false;
            }

            if (this.findPriceTypeIndex(propListByLib.map((item) => item.type)) === -1) {
                return false;
            }

            if (this.findPriceTypeIndex(showListByLib) === -1) {
                return false;
            }

            return !!productItem.pirceValue;
        },

        /**
         * @function name - isMallMarketPriceVisible
         * @description 是否显示市场价格
         * @author mikey.chuhui
         * @date 2020-08-03
         * @param [productItem ={}] {Object}
         * @returns {boolean}
         */
        isMallMarketPriceVisible(productItem = {}) {
            const showListByLib = this.showList[`lib_${productItem.lid}`];
            const propListByLib = this.propList[`lib_${productItem.lid}`];

            if (this.findMallMarketPriceTypeIndex(propListByLib.map((item) => item.type)) === -1) {
                return false;
            }

            if (this.findMallMarketPriceTypeIndex(showListByLib) === -1) {
                return false;
            }

            return !!productItem.mallMarketPrice || productItem.mallMarketPrice == 0;
        },

        formatPrice(item = {}) {
            if (item.actualPrice) {
                return item.actualPrice;
            } else {
                return /\./.test(`${item.mallPrice}`) ? item.mallPrice.toFixed(2) : item.mallPrice.toFixed(2);
            }
        },

        formatMarketPrice(item = {}) {
            return /\./.test(`${item.mallMarketPrice}`)
                ? item.mallMarketPrice.toFixed(2)
                : item.mallMarketPrice.toFixed(2);
        },

        findPriceTypeIndex(list = []) {
            const MALL_PRICE_TYPE = 11;
            return list.findIndex((item) => item === MALL_PRICE_TYPE);
        },

        findMallMarketPriceTypeIndex(list = []) {
            const MALL_MARKET_PRICE_TYPE = 12;
            return list.findIndex((item) => item === MALL_MARKET_PRICE_TYPE);
        },

        handleBtnClick(productInfo, event) {
            if (PayKnowProduct.isPayKnowProduct(productInfo) || PayProduct.isPayProduct(productInfo)) {
                return;
            }
            event.stopPropagation();
            event.preventDefault();
            const { productFormId, id } = productInfo;
            if (!productFormId || productFormId < 0) return;
            openSiteForm && openSiteForm(productFormId, id, true);
        },

        handlePaginationPageChange(page) {
            // 访客态用接口拿分页的列表数据
            if (this.getLoading) return;
            this.getLoading = true;
            this.getProductListByPage(page)
                .then((res) => {
                    this.getLoading = false;
                    const newList = res.data;

                    this.manageMode && this.$store.dispatch('manage/pauseDataWatch');
                    this.module.productTotalSize = res.totalSize;
                    if (this.paginationStyleIndex == 2 && page !== 1) {
                        // 如果是加载更多样式，则需要插入数组
                        this.productList = this.productList.concat(newList);
                    } else {
                        this.productList = newList;
                    }
                })
                .catch(() => {
                    this.getLoading = false;
                });
        },

        getProductListByPage(page = 1) {
            return getProductList(this.module, page);
        },

        clickAddProductHandler() {
            addProduct([], this.module.id).then((newProductList) => {
                if (!newProductList) {
                    return;
                }
                if (this.flagSelectProduct) {
                    saveProductListToModule(
                        this.module.id,
                        this.module.prop5.concat(newProductList.map(({ id }) => id))
                    );
                } else {
                    recordModuleAndRefreshPage(this.module.id);
                }
            });
        },

        clickSelectProductHandler() {
            selectProductListItem(this.id);
        },

        clickSelectProductGroupHandler() {
            editModule(this.id);
        },

        clickManageProductGroupHandler() {
            manageProductGroup();
        },

        hovermenu: manageUse.hovermenu || function () {},

        isLinkProduct(productType) {
            return productType == 4;
        },
        imgOptsComputed(item) {
            let options = {};
            if (this.isPayProduct(item)) {
                options.cart = {
                    show: this.showShoppingCartForEffect,
                    click: () => {
                        this.addToCart(item);
                    },
                };
            }
            return this.getImgComponentOption(this.module, options);
        },
    },
};
