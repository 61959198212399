import { mapState } from 'vuex';
import { Comm } from '../../utils';
import { GLOBAL_FONT_FAMILY_NAME_CSS_VALUE } from '@/store/modules/manage/modules/globalFont';
const { getComputedBg, getStyleDataByDevice, extend, getWrapStyle, isNewModuleAfter20210707 } = Comm;
const mixins = {
    computed: {
        ...mapState(['device', 'manageMode']),
        iconList() {
            return this.module.prop1;
        },
        moduleStyle() {
            return this.module.prop0;
        },
        privatePattern() {
            return this.module.privatePattern;
        },
        pcPattern() {
            return this.privatePattern.pc || {};
        },
        mobiPattern() {
            return this.privatePattern.mobi || {};
        },
        isMobi() {
            return this.device == 'mobi';
        },
        realPattern() {
            return this.isMobi ? this.mobiPattern : this.pcPattern;
        },
        descData() {
            return this.pcPattern.descSet || {};
        },
        titleData() {
            return this.pcPattern.titleSet || {};
        },
        mobiDescData() {
            return this.mobiPattern.descSet || {};
        },
        mobiTitleData() {
            return this.mobiPattern.titleSet || {};
        },
        iconImgIsCustom() {
            return this.realPattern.irw;
        },
        iconImgCustomSize() {
            return this.realPattern.width;
        },
        iconImgAlign() {
            return this.pcPattern.align;
        },
        iconBgData() {
            return this.pcPattern.ib || {};
        },
        iconHeightPadding() {
            return this.pcPattern.ics;
        },
        iconRowSpace() {
            return this.pcPattern.irs;
        },
        iconCount() {
            let defaultIconCount = 4;
            if (this.isMobi) {
                switch (this.moduleStyle) {
                    case 1:
                        defaultIconCount = 2;
                        break;
                    case 2:
                        defaultIconCount = 1;
                        break;
                    case 3:
                        defaultIconCount = 2;
                        break;
                }
            }
            return this.realPattern.irc == 1 ? this.realPattern.count : defaultIconCount;
        },
        iconCountClass() {
            let classList = [];
            if (this.manageMode) {
                if (this.realPattern.irc == 1) {
                    switch (this.realPattern.count) {
                        case 1:
                            classList.push('count1');
                            break;
                        case 2:
                            classList.push('count2');
                            break;
                        case 3:
                            classList.push('count3');
                            break;
                        case 4:
                            classList.push('count4');
                            break;
                        case 5:
                            classList.push('count5');
                            break;
                        case 6:
                            classList.push('count6');
                            break;
                    }
                } else {
                    switch (this.moduleStyle) {
                        case 1:
                            classList.push(!this.isMobi ? 'count4' : 'count2');
                            break;
                        case 2:
                            classList.push(!this.isMobi ? 'count4' : 'count1');
                            break;
                        case 3:
                            classList.push(!this.isMobi ? 'count4' : 'count2');
                            break;
                    }
                }
            }

            classList.push(this.iconBorderShadowClass);

            return classList;
        },
        iconDescStyle() {
            let style = {};
            if (this.pcPattern.ids == 1) {
                style['display'] = 'none';
                return style;
            }

            const descData = getStyleDataByDevice({
                pcData: this.descData,
                mobiData: this.mobiDescData,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
                isNewModule: this.isNewModuleAfter20210707,
            });

            if (descData.y == 1) {
                style = {
                    'font-size': descData.s ? descData.s + 'px' : '',
                    'font-weight': descData.w == 1 ? 'bold' : 'initial',
                    'font-style': descData.i == 1 ? 'italic' : 'initial',
                    fontFamily: descData.f,
                    'text-decoration': descData.d == 1 ? 'underline' : 'initial',
                };
                if (descData.ugf === 1) {
                    style.fontFamily = GLOBAL_FONT_FAMILY_NAME_CSS_VALUE;
                }
                extend(style, this.getComputedTextColor(descData.c));
            }

            const wrapData = getStyleDataByDevice({
                pcData: this.pcPattern.pl,
                mobiData: this.mobiPattern.pl,
                isMobi: this.isMobi,
            });
            extend(style, getWrapStyle(wrapData));

            return style;
        },
        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },
        iconTitleStyle() {
            let style = {};
            const titleData = getStyleDataByDevice({
                pcData: this.titleData,
                mobiData: this.mobiTitleData,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
                isNewModule: this.isNewModuleAfter20210707,
            });
            if (titleData.y == 1) {
                style = {
                    'font-size': titleData.s ? titleData.s + 'px' : '',
                    'font-weight': titleData.w == 1 ? 'bold' : 'initial',
                    'font-style': titleData.i == 1 ? 'italic' : 'initial',
                    fontFamily: titleData.f,
                    'text-decoration': titleData.d == 1 ? 'underline' : 'initial',
                };
                if (titleData.ugf === 1) {
                    style.fontFamily = GLOBAL_FONT_FAMILY_NAME_CSS_VALUE;
                }
                extend(style, this.getComputedTextColor(titleData.c));
            }
            const wrapData = getStyleDataByDevice({
                pcData: this.pcPattern.ptl,
                mobiData: this.mobiPattern.ptl,
                isMobi: this.isMobi,
            });
            extend(style, getWrapStyle(wrapData));
            return style;
        },
        iconBgStyle() {
            return getComputedBg(this.iconBgData);
        },
        iconContentPadding() {
            let style = {};
            if (!this.isMobi && this.pcPattern.ircs == 1 && this.manageMode) {
                style['padding'] = `${this.iconWidthPadPercent}% ${this.iconWidthPadPercent}%`;
            }
            return style;
        },
        iconImgContainerAlignStyle() {
            let classList = [];
            if (this.iconImgAlign == 1) {
                classList.push('imgAlignLeft');
            }
            if (this.moduleStyle == 2 && this.iconImgAlign == 3) {
                classList.push('imgAlignTop');
            }
            if (this.moduleStyle == 3 && this.iconImgAlign == 2) {
                classList.push('imgAlignCenter');
            }
            return classList;
        },
        itemContainerStyle() {
            let style = {};
            // Object.assign(style, this.iconCountStyle);
            Object.assign(style, this.iconBgStyle);
            return style;
        },
        renderIconList() {
            let renderList = [];
            let count = 1;
            let arrIndex = 0;
            this.iconList.forEach((item) => {
                if (count > this.iconCount) {
                    count = 1;
                    arrIndex++;
                }
                renderList[arrIndex] = renderList[arrIndex] || [];
                renderList[arrIndex].push(item);
                count++;
            });
            return renderList;
        },

        iconWidthPadPercent() {
            let itemWidth = 1;
            let width = 0;
            // 样式一：最大占 40%, 样式二：最大占 20%，样式三：最大占 40%;
            switch (this.moduleStyle) {
                case 1:
                    width = itemWidth * 0.2;
                    break;
                case 2:
                    width = itemWidth * 0.1;
                    break;
                case 3:
                    width = itemWidth * 0.2;
                    break;
            }
            return width * this.iconRowSpace * 100;
        },

        iconBorderShadowClass() {
            return this.pcPattern.is == 0 ? 'iconBgShadow' : '';
        },
    },
    methods: {
        getLinkUrl(link) {
            if (!link) {
                return;
            }
            return link.lt == -1 ? undefined : link.url;
        },
        handleItemMouseenter(item) {
            this.manageMode && window._store.dispatch('manage/pauseDataWatch');
            this.$set(item, 'hovering', true);
        },
        handleItemMouseleave(item) {
            this.manageMode && window._store.dispatch('manage/pauseDataWatch');
            this.$set(item, 'hovering', false);
        },
        getTitleHoverStyle(item) {
            const style = {};
            if (item.hovering && !this.isMobi && this.isNewModuleAfter20210707 && this.titleData.y) {
                extend(style, this.getComputedTextColor(this.titleData.sc));
            }
            return style;
        },
        iconImgStyle() {
            let style = {};
            if (this.iconImgIsCustom && this.manageMode) {
                if (this.moduleStyle == 1 || this.moduleStyle == 3) {
                    style['width'] = this.iconImgCustomSize + '%';
                } else if (this.moduleStyle == 2) {
                    style['width'] = 100 - this.iconImgCustomSize + '%';
                }
            }
            return style;
        },
        encodeHtmlStr(html) {
            return html && html.replace
                ? html
                      .replace(/&/g, '&amp;')
                      // .replace(/ /g, '&nbsp;')
                      // .replace(/\b&nbsp;+/g, ' ')
                      .replace(/</g, '&lt;')
                      .replace(/>/g, '&gt;')
                      .replace(/\\/g, '&#92;')
                      .replace(/'/g, '&#39;')
                      .replace(/"/g, '&quot;')
                      .replace(/\n/g, '<br/>')
                      .replace(/\r/g, '')
                : html;
        },
        getComputedTextColor(color) {
            const textColorStyle = {};
            if (color) {
                if (color.indexOf('linear-gradient') > -1) {
                    textColorStyle.backgroundImage = color;
                    textColorStyle.color = 'transparent';
                } else {
                    textColorStyle.backgroundImage = '';
                    textColorStyle.color = color;
                }
            }
            return textColorStyle;
        },
    },
};

export { mixins };
