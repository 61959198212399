var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "module",
      staticClass: "module_image_content",
      class: _vm.richImgClass,
      style: _vm.imageContentStyle,
    },
    [
      !_vm.isNotImg
        ? _c(
            "a",
            {
              key: _vm.newImgSrc,
              ref: "button",
              style: _vm.styleForImageWrap,
              attrs: {
                href: _vm.link,
                target: _vm.linkTarget,
                rel: _vm.linkNofollow,
                onclick: _vm.jumpOnClickStr,
              },
            },
            [
              _vm.hasDefaultImg &&
              _vm.module.prop2 == 1 &&
              !_vm.isMobi &&
              !_vm.fullScreen
                ? _c("ImageComponent", {
                    directives: [
                      {
                        name: "lazyload2",
                        rawName: "v-lazyload2",
                        value: {
                          id: _vm.module.id,
                          src: _vm.newImgSrc,
                          naturalWidth: _vm.naturalWidth,
                          naturalHeight: _vm.naturalHeight,
                        },
                        expression:
                          "{\n                id: module.id,\n                src: newImgSrc,\n                naturalWidth: naturalWidth,\n                naturalHeight: naturalHeight,\n            }",
                      },
                    ],
                    staticClass: "module_image--bg",
                    class: _vm.photoItemClass,
                    style: _vm.bgImageStyle,
                    attrs: {
                      src: _vm.newImgSrc,
                      "data-alt": _vm.module.prop5,
                      "img-effects": _vm.imageEffects,
                      webp: _vm.webpOptOpen,
                    },
                  })
                : _vm.hasDefaultImg
                ? _c(
                    "ImageComponent",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "lazyload2",
                            rawName: "v-lazyload2",
                            value: {
                              id: _vm.module.id,
                              src: _vm.newImgSrc,
                              naturalWidth: _vm.naturalWidth,
                              naturalHeight: _vm.naturalHeight,
                            },
                            expression:
                              "{\n                id: module.id,\n                src: newImgSrc,\n                naturalWidth: naturalWidth,\n                naturalHeight: naturalHeight,\n            }",
                          },
                        ],
                        class: _vm.photoItemClass,
                        style: _vm.styleForImage,
                        attrs: {
                          original: _vm.module.psrc,
                          src: _vm.module.psrc,
                          alt: _vm.module.prop5,
                          "img-effects": _vm.imageEffects,
                          webp: _vm.webpOptOpen,
                        },
                      },
                      "ImageComponent",
                      _vm._d({}, [_vm.draggableAttr, _vm.draggable])
                    )
                  )
                : _c(
                    "ImageComponent",
                    _vm._b(
                      {
                        ref: "imgNoLazyload",
                        class: _vm.photoItemClass,
                        style: _vm.styleForImage,
                        attrs: {
                          src: _vm.newImgSrc,
                          original: _vm.module.psrc,
                          alt: _vm.module.prop5,
                          "img-effects": _vm.imageEffects,
                          "always-show-src": "",
                          webp: _vm.webpOptOpen,
                        },
                      },
                      "ImageComponent",
                      _vm._d({}, [_vm.draggableAttr, _vm.draggable])
                    )
                  ),
              _vm._v(" "),
              !_vm.isPreviewMode
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showClipContainer,
                          expression: "showClipContainer",
                        },
                      ],
                      class: _vm.viewPortClass,
                      style: _vm.viewPortStyle,
                    },
                    [
                      _c("img", {
                        style: _vm.clipInnerImageStyle,
                        attrs: { src: _vm.module.psrc },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : !_vm.isPreviewMode
        ? _c("img", { staticClass: "add_img", attrs: { src: _vm.addImgPath } })
        : _c("img", { attrs: { src: _vm.noImgPath } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }