export function getMousePosition(vertical, e) {
    let zoom = 1;
    if (window.visualViewport) {
        zoom = +(window.visualViewport.width / document.body.getBoundingClientRect().width).toFixed(2);
    }
    return (vertical ? e.clientY : e.pageX) / zoom;
}

export function pauseEvent(e) {
    e.stopPropagation();
    e.preventDefault();
}

export function isNotTouchEvent(e) {
    return e.touches.length > 1 || (e.type.toLowerCase() === 'touchend' && e.touches.length > 0);
}

export function getTouchPosition(vertical, e) {
    let zoom = 1;
    if (window.visualViewport) {
        zoom = +(window.visualViewport.width / document.body.getBoundingClientRect().width).toFixed(2);
    }
    return (vertical ? e.touches[0].clientY : e.touches[0].pageX) / zoom;
}

export function transformTime(value) {
    let time = '';
    let h = parseInt(value / 3600);
    value %= 3600;
    let m = parseInt(value / 60) || '00';
    let s = parseInt(value % 60) || '00';
    if (h > 0) {
        time = formatTime(h + ':' + m + ':' + s);
    } else {
        time = formatTime('00:' + m + ':' + s);
    }
    return time;
}

function formatTime(value) {
    let time = '';
    let s = value.split(':');
    let i = 0;
    for (; i < s.length - 1; i++) {
        time += s[i].length == 1 ? '0' + s[i] : s[i];
        time += ':';
    }
    time += s[i].length == 1 ? '0' + s[i] : s[i];
    return time;
}
