export function fileDownloadLimitHandler() {
    if (global.Vue.prototype.$isServer) return;

    if (window._store.state.isJzDownloadCdnFlowLimit === true && !window._store.state._oem) {
        const manageMode = window._store.state.manageMode;
        Fai.ing(
            manageMode
                ? `您的文件下载流量过大，导致下载速度缓慢，<a target="_blank" style="color:red;text-decoration: underline;" href="//${window.Hosts.jzHome}/blog/1247639">详情可了解>></a>`
                : '网站文件下载速度缓慢，请联系网站管理员',
            !manageMode || window._store.state.device == 'mobi',
            3000
        );
        return true;
    }
    return false;
}
