
import { mapGetters, mapState, mapActions } from 'vuex';

import { getComputedBg } from '@/components/utils.js';
import { Comm } from '../../utils.js';
import Project from '../../Project.vue';
import { setColHoverStatus } from './hoverStyle/utils.js';

const manageComponents = {};

// 列组件
export default {
    name: 'jz-web-col',
    components: {
        Project,
        ...manageComponents,
    },
    inject: ['isSysRow', 'rowNewResponsiveConversion', 'rowNewMobiResponsiveConversion', 'websName'],
    props: ['col', 'rowid', 'row', 'carouselIndex', 'cloneModuleId', 'carouselCols'],
    data() {
        return {
            colManageInstance: null,
            dragHandleSelector: '',
        };
    },
    computed: {
        ...mapState('row', ['rowEditing']),
        ...mapState([
            'device',
            'rowCol',
            'manageMode',
            'newResponsiveConversion',
            'newMobiResponsiveConversion',
            'templateNewResponsiveConversion',
            'templateNewMobiResponsiveConversion',
            'responsiveInfo',
            'modules',
        ]),
        ...mapState('header', ['fixedHeaderHeight']),
        ...mapState('popupZone', {
            popupZoneRowId: (state) => state.rowId,
        }),
        ...mapGetters(['info', 'isFullScreen']),
        ...mapGetters('popupZone', ['isEditMode']),

        dragDisabled() {
            return !this.manageMode || this.isMobi || (this.popupZoneRowId === this.rowid && !this.isEditMode);
        },
        funcPanel() {
            return this.$store.state.manage.funcPanel;
        },
        isCarouselRow() {
            // 是否是轮播通栏
            return this.$store.getters.isCarouselRow(this.row.id);
        },
        index() {
            return this?.cols?.indexOf(this.col);
        },
        cols() {
            if (this.isCarouselRow) {
                return this.curRowPattern?.['pc']?.e?.rbs?.[this.carouselIndex]?.cols || {};
            }
            return this.row.cols;
        },
        mIds() {
            var curMIds = [];
            var mIds = this.col.mIds,
                id = null;
            for (var i = 0; i < mIds.length; i++) {
                id = mIds[i];
                if (id) {
                    if (Comm.isSectionId(id)) {
                        //部件id
                        curMIds.push(id);
                    } else {
                        //模块id
                        const _module = this.$store.state.modules['module' + id];
                        if (_module) {
                            if (!(this.$store.state.colId === 2 && _module.style === 34)) {
                                curMIds.push(id);
                            }
                        } else {
                            if (this.manageMode) {
                                delModuleData(id);
                            }
                        }
                    }
                }
            }

            return curMIds;
        },
        type() {
            return this.col.type;
        },
        rIds() {
            return this.col.rIds;
        },
        rows() {
            const curRows = [];
            for (const id of this.rIds) {
                const row = this.$store.state.rows['row' + id];
                row && curRows.push(row);
            }
            return curRows;
        },
        curRow() {
            return this.$store.state.rows[`row${this.rowid}`];
        },
        curRowPattern() {
            return this.$store.state.rows[`row${this.rowid}`].pattern;
        },
        colOrder() {
            // mobi专用，改组件还没有做到服务器渲染，所以设备限制放在css做
            return this.col.pattern.mo;
        },
        hovermenu() {
            return () => {
                if (!this.openRowAndColHover || this.isMobi) {
                    return null;
                }
                const isActive = this.col.isHovering;

                const offset = {};
                let resInfoPattern = this.$store.state.responsiveInfo.pattern;
                // 悬停，固定的顶部高度
                const headerHeight = this.$store.state.header.headerStyleStatus.headerHeight;
                // 是否悬浮
                let isFloatSetting = resInfoPattern.rollingScreen.hftt === 1;
                // 是否固定
                let isFixedSetting = resInfoPattern.header.fixedTop;
                if (this.$store.state.fullScreen && isFixedSetting && isFloatSetting) {
                    offset.x = 0;
                    offset.y = headerHeight;
                }
                return {
                    type: 'shortcut',
                    styleMode: 3,
                    posMode: 14,
                    ...(this.$store.state.fullScreen && offset.y ? { offset } : {}),
                    list: [
                        {
                            text: '初始',
                            style: !isActive ? 'icon_tab_active' : 'icon_tab',
                            svgIcon: !isActive ? 'tab_active' : 'tab',
                            click: () => {
                                if (!isActive) {
                                    return;
                                }
                                setColHoverStatus({ rowId: this.rowid, colIndex: this.index, isHover: false });
                            },
                        },
                        {
                            text: '悬停',
                            style: isActive ? 'icon_tab_active' : 'icon_tab',
                            svgIcon: isActive ? 'tab_active' : 'tab',
                            click: () => {
                                if (isActive) {
                                    return;
                                }
                                setColHoverStatus({ rowId: this.rowid, colIndex: this.index, isHover: true });
                            },
                        },
                    ],
                };
            };
        },
        classes() {
            var classes = ['jz_web_col', 'jz_web_col' + this.index];
            if (this.isSysModuleCol) {
                classes.push('jz_col_sys_module');
            } else if (this.isNavCol) {
                classes.push('jz_col_sys_section');
            }
            this.isEmpty && classes.push('jz_col_empty');
            if (this.cols.length > 1) {
                classes.push('J_multi_cols');
            }
            // 判断是否是行嵌套
            if (this.isNestedRow) {
                classes.push('J_col_row');
            }

            classes.push('col_mobi_order_' + this.colOrder);

            if (this.openRowAndColHover && !this.isMobi) {
                // 悬停列相关类
                if (this.col.isHovering) {
                    classes.push('isHovering');
                }

                if (!this.manageMode || this.col.increaseZIndex) {
                    classes.push('increase_zIndex');
                }

                const isBgZoom = this.colPattern.hoverBgEffect === 1;
                if (isBgZoom) {
                    classes.push('hover_bg_effect');
                }

                const isTransform = this.colPattern.hoverEffect === 1;
                if (isTransform) {
                    classes.push('hover_transform_effect');
                }
            }

            // 手机展示悬停内容
            if (this.mobiShowHoverContent) {
                classes.push('show_hover_content_in_mobi');
            }

            if (!this.canAddMaterial) {
                classes.push('J_disable_add_material');
            }

            classes = classes.reduce((o, c) => {
                o[c] = true;
                return o;
            }, {});
            return Object.assign(classes, this.classesEditing);
        },
        mobiShowInitialContent() {
            return this.openRowAndColHover && this.isMobi && this.colPattern.showContentInMobi === 0;
        },
        mobiShowHoverContent() {
            return this.openRowAndColHover && this.isMobi && this.colPattern.showContentInMobi === 1;
        },
        mobiBgIsNotDefault() {
            // 在mobi下，背景是否是默认值，不是默认值隐藏hover背景
            if (!this.isMobi || !this.openRowAndColHover) {
                return false;
            }
            return this.colPattern.mbg.y !== 0;
        },
        colPattern() {
            return this.col.pattern;
        },
        classesEditing() {
            let colClass = 'jz_col_' + this.colPattern.g;
            if (this.colPattern.g.toString().indexOf('.') !== -1) {
                colClass = 'jz_col_' + this.colPattern.g.toString().replace('.', '_');
            }
            return {
                [colClass]: this.colPattern.g,
            };
        },
        isSysModuleCol() {
            if (this.row.type == 2) {
                if (this.cols.length == this.index + 1) {
                    return true;
                }
            }
            return false;
        },
        isNavCol() {
            return this.mIds.includes(150);
        },
        isTitleCol() {
            return this.mIds.includes(153);
        },
        isMultiLanguageCol() {
            return this.mIds.includes(154);
        },
        isMemberLoginCol() {
            return this.mIds.includes(151);
        },
        isFooterCol() {
            return this.mIds.includes(152);
        },
        isEmptyInHover() {
            return this.col.hoverMIds.length == 0;
        },
        isEmpty() {
            if (this.type == 0) {
                return this.mIds.length == 0;
            } else if (this.type == 1) {
                return this.rows.length == 0;
            }
            return false;
        },
        pattern() {
            return this.col.pattern;
        },
        openRowAndColHover() {
            return this.curRowPattern.pc.isHover === 1 && this.col.rIds.length == 0;
        },
        initialBg() {
            if (!this.manageMode) {
                return;
            }
            const bgStyle = getComputedBg(this.pattern.bg, undefined, { manageV1: true });
            return bgStyle;
        },
        colStyle() {
            if (!this.manageMode) {
                return;
            }
            //如果mobi默认，就取pc的数据
            var pattern = this.pattern,
                bg = pattern.bg,
                bGstyle = getComputedBg(bg, undefined, { manageV1: true }),
                style = {},
                unit =
                    (this.newResponsiveConversion ||
                        (this.isSysRow && this.templateNewResponsiveConversion) ||
                        this.rowNewResponsiveConversion) &&
                    !this.isMobi &&
                    this.popupZoneRowId !== this.rowid
                        ? 'vw '
                        : '% ';

            if (!this.isMobi || pattern.mbg.y == 0) {
                // 如果是手机设置了独立设置则手机不输出电脑样式
                if (!this.openRowAndColHover) {
                    Comm.extend(style, bGstyle);
                }
            }

            if (pattern.mt || pattern.mr || pattern.mb || pattern.ml) {
                let _outerWidth = 1,
                    mobiClientWidth = 1;
                if (this.isMobi) {
                    if (
                        (this.newMobiResponsiveConversion ||
                            (this.isSysRow && this.templateNewMobiResponsiveConversion) ||
                            this.rowNewMobiResponsiveConversion) &&
                        this.popupZoneRowId !== this.rowid
                    ) {
                        // 新得间距换算逻辑
                        unit = 'vw ';
                        mobiClientWidth = 375;
                        _outerWidth = Math.min(window.innerWidth, window.outerWidth);
                    }
                }
                style.padding = `${((pattern.mt * mobiClientWidth) / _outerWidth) * 100}${unit}${pattern.mr * 100}% ${
                    ((pattern.mb * mobiClientWidth) / _outerWidth) * 100
                }${unit}${pattern.ml * 100}%`;
            }

            if (!this.isMobi && pattern.m) {
                if (pattern.m.mt || pattern.m.mr || pattern.m.mb || pattern.m.ml) {
                    style.margin = `${pattern.m.mt * 100}${unit}${pattern.m.mr * 100}% ${pattern.m.mb * 100}${unit}${
                        pattern.m.ml * 100
                    }%`;
                }
            }

            if (this.isMobi) {
                let _outerWidth = 1,
                    mobiClientWidth = 1;
                if (
                    (this.newMobiResponsiveConversion ||
                        (this.isSysRow && this.templateNewMobiResponsiveConversion) ||
                        this.rowNewMobiResponsiveConversion) &&
                    this.popupZoneRowId !== this.rowid
                ) {
                    // 新得间距换算逻辑
                    unit = 'vw ';
                    mobiClientWidth = 375;
                    _outerWidth = Math.min(window.innerWidth, window.outerWidth);
                }
                const mbgStyle = getComputedBg(pattern.mbg, undefined, { manageV1: true });
                if (mbgStyle.background !== '') {
                    Comm.extend(style, mbgStyle);
                }

                if (pattern.mm.t == 1) {
                    style.padding = `${((pattern.mm.mt * mobiClientWidth) / _outerWidth) * 100}${unit}${
                        pattern.mm.mr * 100
                    }% ${((pattern.mm.mb * mobiClientWidth) / _outerWidth) * 100}${unit}${pattern.mm.ml * 100}%`;
                }

                if (pattern.mm2.t == 1) {
                    style.margin = `${((pattern.mm2.mt * mobiClientWidth) / _outerWidth) * 100}${unit} 0 ${
                        ((pattern.mm2.mb * mobiClientWidth) / _outerWidth) * 100
                    }${unit} 0`;
                }
            }

            // hover状态下除了外间距，都由cssStyles接管
            if (this.openRowAndColHover && this.col.isHovering) {
                return { margin: style.margin };
            }

            if (this.openRowAndColHover && !this.isMobi) {
                // 开启悬停状态，由 cssStyles 接管 padding
                delete style.padding;
            }

            return style;
        },
        isMobi() {
            return this.device == 'mobi';
        },
        isMultiCols() {
            return this.cols.length > 1;
        },
        // 判断是否是行嵌套
        isNestedRow() {
            return this.$store.getters.isNestRow(this.row.id);
        },
        isFooterOrHeaderRows() {
            return (
                this.$store.state.responsiveInfo.footerRowIds.indexOf(this.row.id) !== -1 ||
                this.$store.state.responsiveInfo.headerRowIds.indexOf(this.row.id) !== -1
            );
        },
        memberLogin() {
            return this.info.pattern.memberLogin;
        },
        title() {
            return this.info.pattern.title;
        },
        multiLanguage() {
            return this.info.pattern.multiLanguage;
        },
        nav() {
            return this.info.pattern.nav;
        },
        footer() {
            return this.info.pattern.footer;
        },
        features() {
            return this.colManageInstance?.getFeatures() ?? [];
        },
        colResizableData() {
            return {
                col: this.col,
                rowid: this.rowid,
                row: this.row,
                isEmpty: this.isEmpty,
                index: this.index,
                cols: this.carouselCols,
            };
        },
        canAddMaterial() {
            if (this.openRowAndColHover) {
                return false;
            }
            //全屏导航和导航自由排版
            if ([6, 7].includes(this.row.type)) {
                return false;
            }
            if (this.isFullScreen) {
                return !this.isMultiCols && !this.isFooterOrHeaderRows && !this.isNestedRow && this.mIds.length == 0;
            }
            return !this.isMultiCols && !this.isNestedRow && this.mIds.length == 0;
        },
        isPopupZoneRow() {
            return this.row.type === 8;
        },

        dndContext: () => dndContext,
        colIsEmpty() {
            return (
                this.manageMode &&
                this.isEmpty &&
                !this.isMobi &&
                this.designAuth &&
                ((this.isEditMode && this.isPopupZoneRow) || !this.isPopupZoneRow)
            );
        },
        hoverColIsEmpty() {
            return this.manageMode && this.openRowAndColHover && this.isEmptyInHover && !this.isMobi && this.designAuth;
        },
        limitIndexs() {
            return [];
        },
        colClass() {
            return {
                web_col_content_empty: this.isEmpty,
                web_col_content_rows: this.type == 1,
                initial_col_content: this.openRowAndColHover,
                col_content_unsortable: this.openRowAndColHover && this.col.isHovering && !this.isMobi,
                col_disable_add_container: this.manageMode && this.openRowAndColHover,
                web_col_content: true,
                web_col_content_sticky: this.isStickyCol,
                jz_col_module_image_contour: this.checkImageScaleClass(),
            };
        },
        hoverColClass() {
            return {
                web_col_content_empty: this.isEmptyInHover,
                col_content_unsortable: this.openRowAndColHover && !this.col.isHovering && !this.isMobi,
                col_disable_add_container: this.manageMode && this.openRowAndColHover,
                hover_col_content: true,
                web_col_content: true,
                web_col_content_sticky: this.isStickyHoverCol,
                jz_col_module_image_contour: this.checkImageScaleClass(this.col.hoverMIds),
            };
        },
        colContentStyle() {
            const style = {};
            if (this.isStickyCol) {
                style.top = `${(this.manageMode ? this.pageTop : 0) + this.fixedHeaderHeight}px`;
            }
            return style;
        },
        isStickyCol() {
            return this.device === 'pc' && this.col.pattern.fixed;
        },
        isStickyHoverCol() {
            return this.device === 'pc' && this.openRowAndColHover && this.col.pattern.hoverFixed;
        },
    },
    render() {
        const projectsTemplate = (mIds) => {
            return mIds.map((id, index) => (
                <Project
                    id={id}
                    key={id}
                    module-index={index}
                    clone-module-id={this.cloneModuleId}
                    rowid={this.rowid}
                ></Project>
            ));
        };
        const manageColTemplate = ({ vshow, classes, onDrop, mIds, colIsEmpty, isHoverCol }) => {
            return (
                <DndContainer
                    style={isHoverCol ? '' : this.colContentStyle}
                    class={classes}
                    vShow={vshow}
                    should-accept-drop={this.shouldAcceptDrop}
                    should-not-accept-drop-but-tips={this.shouldNotAcceptDropButTips}
                    get-child-payload={this.getChildPayload(mIds)}
                    drag-handle-selector={this.dragHandleSelector}
                    drag-disabled={this.dragDisabled}
                    get-placeholder-size={this.getPlaceholderSize}
                    get-ghost-parent={this.getGhostParent}
                    empty={colIsEmpty}
                    limit-indexs={this.limitIndexs}
                    on={{
                        click: this.changePanelEditCol,
                        drop: onDrop,
                    }}
                >
                    <template slot="default">{projectsTemplate(mIds)}</template>
                    <template slot="empty">
                        <div class="web_col_content_empty_tips">
                            <div
                                class="web_col_content_empty_tips_btn"
                                on={{ click: isHoverCol ? this.addHoverModule : this.addModule }}
                            >
                                <i class="web_col_content_empty_tips_icon"></i>
                                <div class="web_col_content_empty_tips_text">添加模块</div>
                            </div>
                            {this.canAddMaterial && (
                                <div class="web_col_content_material_add_btn" on={{ click: this.addMaterial }}>
                                    <svg aria-hidden="true" class="web_col_content_material_add_icon">
                                        <use href="#icon_material_add"></use>
                                    </svg>
                                    <div class="web_col_content_empty_tips_text">添加素材</div>
                                </div>
                            )}
                            <div class="web_col_content_empty_tips_label">可拖动模块进来</div>
                        </div>
                    </template>
                </DndContainer>
            );
        };
        const visitorColTemplate = ({ vshow, classes, mIds, isHoverCol }) => {
            return (
                <div vShow={vshow} class={classes} style={isHoverCol ? '' : this.colContentStyle}>
                    {projectsTemplate(mIds)}
                </div>
            );
        };
        return (
            <div
                ref="webCol"
                v-hovermenu={{ hovermenu: this.hovermenu, args: {} }}
                attrs={{ _index: this.index, _row: this.rowid }}
                class={this.classes}
                style={this.colStyle}
                on={{
                    mouseenter: this.enterHoverStatus,
                    mouseleave: this.leaveHoverStatus,
                }}
            >
                {/* 初始背景 */}
                {this.openRowAndColHover && !this.mobiShowHoverContent && !this.mobiBgIsNotDefault && (
                    <div class="initial_col_bg" style={this.initialBg}></div>
                )}

                {/* 初始模块 */}
                {!this.mobiShowHoverContent &&
                    (this.type == 1 ? (
                        <div class={this.colClass} on={{ click: this.changePanelEditCol }}>
                            {/* 列的内容为嵌套行 */}
                            {this.rows.map((row, index) => {
                                return (
                                    <jz-web-row
                                        key={row.id}
                                        row={row}
                                        index={index}
                                        hide-tool-bar={true}
                                        hide-material-line={true}
                                        clone-module-id={this.cloneModuleId}
                                    ></jz-web-row>
                                );
                            })}

                            {/* 列的内容为模块 */}
                        </div>
                    ) : this.manageMode ? (
                        manageColTemplate({
                            vshow: true,
                            classes: this.colClass,
                            onDrop: this.onDrop,
                            mIds: this.col.mIds,
                            colIsEmpty: this.colIsEmpty,
                            isHoverCol: false,
                        })
                    ) : (
                        visitorColTemplate({
                            vshow: true,
                            classes: this.colClass,
                            mIds: this.col.mIds,
                            isHoverCol: false,
                        })
                    ))}

                {/* 悬停背景 */}
                {this.openRowAndColHover && !this.mobiShowInitialContent && !this.mobiBgIsNotDefault && (
                    <div class="hover_col_bg"></div>
                )}

                {/* 遮罩 */}
                {this.openRowAndColHover && !this.isMobi && <div class="hover_col_mask_bg"></div>}

                {/* 悬停模块 */}
                {this.openRowAndColHover &&
                    !this.mobiShowInitialContent &&
                    (this.manageMode
                        ? manageColTemplate({
                              vshow: this.col.defaultShow || this.isMobi,
                              classes: this.hoverColClass,
                              onDrop: this.onHoverDrop,
                              mIds: this.col.hoverMIds,
                              colIsEmpty: this.hoverColIsEmpty,
                              isHoverCol: true,
                          })
                        : visitorColTemplate({
                              vshow: this.col.defaultShow || this.isMobi,
                              classes: this.hoverColClass,
                              mIds: this.col.hoverMIds,
                              isHoverCol: true,
                          }))}
                {this.openRowAndColHover && this.manageMode && !this.isMobi && (
                    <template>
                        <div class="manage_hover_line manage_hover_line_left"></div>
                        <div class="manage_hover_line manage_hover_line_right"></div>
                        <div class="manage_hover_line manage_hover_line_top"></div>
                        <div class="manage_hover_line manage_hover_line_bottom"></div>
                    </template>
                )}

                {this.manageMode && <features inner features={this.features}></features>}
            </div>
        );
    },
    watch: {
        openRowAndColHover: {
            handler(val) {
                if (val) {
                    this.initHoverData(true);
                }
            },
        },
    },
    created() {
        this.initHoverData();
    },
    mounted() {
        if (this.manageMode && this.moduleManageAuth) {
            this.initColManageInstance();
        }
        this.addRowColId();
    },
    methods: {
        ...mapActions('manage/dnd', ['setAppointArea', 'clearAppointArea']),
        async initHoverData(immediate = false) {
            if (this.initiatedHoverData) {
                return;
            }
            if (!this.openRowAndColHover) {
                return false;
            }
            this.initiatedHoverData = true;
            if (this.manageMode && !immediate) {
                await this.$nextTick();
                this.$store.dispatch('manage/pauseDataWatch');
            }
            this.$set(this.col, 'isHovering', false);
            this.$set(this.col, 'defaultShow', false);
            if (this.manageMode) {
                this.$set(this.col, 'increaseZIndex', false);
                this.$watch(
                    () => this.isMobi,
                    (newValue) => {
                        if (newValue) {
                            this.$store.dispatch('manage/pauseDataWatch');
                            this.$set(this.col, 'isHovering', false);
                        }
                    }
                );
            }
        },
        initColManageInstance() {
            this.colManageInstance = new ColManage();
            this.colManageInstance.registerFeatures();
            this.$watch(
                () => this.colResizableData,
                () => {
                    this.colManageInstance.setColResizableData(this.colResizableData);
                },
                { immediate: true }
            );
        },
        checkImageScaleClass(mIds = this.mIds) {
            // 图片列等高缩放
            let scaleClass = false,
                mIdsLen = 1,
                moduleIndex = 0;
            if (this.rowid === 3) {
                // 顶部特殊处理
                let headerType = this.isNavCol
                    ? this.nav.hide
                    : this.isTitleCol
                    ? this.title.h
                    : this.isMultiLanguageCol
                    ? this.multiLanguage.h
                    : this.memberLogin.h;
                headerType && (mIdsLen = 2);
            } else if (this.rowid === 4) {
                // 底部处理
                let footerType =
                    this.isFooterCol && (this.footer.support || this.footer.copyright.type || this.footer.admin);
                footerType && (mIdsLen = 2);
            }
            if (mIds.length > mIdsLen || !!this.$store.state.fullScreen || this.device === 'mobi' || !this.isMultiCols)
                return scaleClass;

            let moduleId = mIds[moduleIndex],
                module = this.$store.state.modules[`module${moduleId}`];
            if (!module) return scaleClass;
            let styleId = module['style'];
            if (styleId !== 7) return scaleClass;
            let ist = module['prop2'];
            scaleClass = ist === 1;
            return scaleClass;
        },
        addMaterial() {
            this.$store.commit('manage/funcPanel/funcPanelChange', {
                active: true,
                type: 'module',
                second: 'MaterialMenu',
                showAnimation: this.funcPanel.active && this.funcPanel.type == 'module',
            });
            this.setAppointArea({
                type: 'material',
                area: 'row',
                target: this.row,
            });
            Root.vm?.$refs?.jzHideSetBar?.handleChange(true);
        },
        addHoverModule() {
            this.addModule({ isHover: true });
        },
        addModule({ isHover = false } = {}) {
            if (this.rowEditing) {
                return;
            }

            if (!this.isEditMode) {
                this.$store.commit('manage/funcPanel/funcPanelChange', {
                    active: true,
                    type: 'module',
                    second: undefined,
                    showAnimation: this.funcPanel.active && this.funcPanel.type == 'module',
                    isOpenHoverCol: this.openRowAndColHover,
                });
            }
            this.setAppointArea({
                type: 'module',
                area: 'col',
                tag: this.openRowAndColHover && 'hoverCol',
                target: isHover ? this.col.hoverMIds : this.col.mIds,
            });

            Root.vm?.$refs?.jzHideSetBar?.handleChange(true);
        },
        projectKey(id) {
            return id;
        },
        changePanelEditCol(e) {
            if (this.manageMode) {
                e.stopPropagation();
            }
            Root.changePanelEditCol && Root.changePanelEditCol(this.rowid, this.index);
            Root.changePanelEditRow && Root.changePanelEditRow(this.row.id);
        },
        addRowColId() {
            if (this.type == 1) {
                this.rows.forEach((value) => {
                    if (!this.rowCol.includes(value.id)) {
                        this.$store.commit('addRowCol', value.id);
                    }
                });
            }
        },
        enterHoverStatus() {
            this.setHoverStatus(true);
        },
        leaveHoverStatus() {
            this.setHoverStatus(false);
        },
        setHoverStatus(toHover) {
            if (this.manageMode) {
                return;
            }
            setColHoverStatus({ rowId: this.rowid, colIndex: this.index, isHover: toHover });
        },
        shouldAcceptDrop(sourceContainerOptions, payload) {
            const { dropTag, tag } = payload;
            //控件类型处理
            //导航
            if (dropTag.includes('row' + this.rowid)) {
                return true;
            }
            //标题，多语言，登陆
            if (dropTag.includes(this.websName)) {
                return true;
            }

            //素材条件 非内容区，嵌套通栏（普通通栏），悬停（列）通栏，通栏多列，列里面有内容
            if (payload.type == 'material' && this.canAddMaterial && this.row.type != 5) {
                return true;
            }
            if (this.openRowAndColHover && tag && tag.find((item) => ['tabpack', 'accordion'].includes(item))) {
                return false;
            }
            if (dropTag.includes('col')) {
                return true;
            }
            return false;
        },
        shouldNotAcceptDropButTips(sourceContainerOptions, payload) {
            const { tag = [] } = payload;
            if (this.openRowAndColHover && (tag.includes('tabpack') || tag.includes('accordion'))) {
                return true;
            }
            if (payload.type == 'material' && (!this.canAddMaterial || this.row.type != 5)) {
                return true;
            }
            return false;
        },
        getChildPayload(mIds) {
            return (index) => {
                const id = mIds[index];
                if (id > 200 || id < 150) {
                    //模块类型
                    const module = this.modules['module' + id];
                    let { tag, dropTag } = moduleConfigMap[module.style]; //模块放置信息

                    if ([13, 14].includes(id)) {
                        //文章推荐 & 产品推荐
                        //推荐模块使用的是文章列表 、产品列表，因此要重写 dropTag， 因其只能放在模块系统行
                        dropTag = [`row${this.rowid}`]; //因其只能从当前的模块系统行中拖车，因此，可以直接拿当前行信息
                    }
                    const payload = {
                        data: module,
                        id,
                        tag,
                        dropTag,
                        action: 'move',
                    };
                    return payload;
                } else if ([150, 151, 153, 154].includes(id)) {
                    //控件类型 导航，多语言等等
                    return {
                        id,
                        action: 'move',
                        dropTag: id == 150 ? ['row3'] : [this.websName],
                    };
                }
            };
        },
        getPlaceholderSize() {
            return 100;
        },
        getGhostParent() {
            return this.$refs.webCol;
        },
        onHoverDrop(dropResult) {
            this.dropAdd(dropResult, this.col.hoverMIds);
        },
        onDrop(dropResult) {
            this.dropAdd(dropResult, this.col.mIds);
        },
        async dropAdd(dropResult, modules) {
            //添加模块
            const payload = dropResult.payload;
            const isAdd = payload.action === 'add';
            if (isAdd) {
                const { style, name, type } = payload;
                if (payload.type == 'material') {
                    //添加素材
                    const rows = await addNewMaterial({
                        materialId: payload.id,
                        isNowCategoryMe: payload.isNowCategoryMe,
                        maxLimit: false,
                    });
                    const notColRow = rows.filter((row) => !row.colRow);
                    const sourceRow = notColRow[0];
                    const sourceRowId = sourceRow.id;
                    combineMatrialToRow(this.curRow, sourceRow);
                    delete window._store.state.rows[`row${sourceRowId}`];
                    this.clearAppointArea();
                    return;
                }
                const newModuleId = await addNewModule1({ style, name, type: type || 1 });
                modules.splice(dropResult.addedIndex, 0, newModuleId);
                afterAddNewModule({ moduleId: newModuleId, ...payload });
            } else {
                if (dropResult.removedIndex !== null) {
                    modules.splice(dropResult.removedIndex, 1);
                }
                if (dropResult.addedIndex !== null) {
                    modules.splice(dropResult.addedIndex, 0, payload.id);
                }
            }
        },
    },
};
