import Vuex from 'vuex';
import mutations from './mutations';
import getters from './getters';
import backToTop from './modules/backToTop/index';
import wx from './modules/wx/index';
import header from './modules/header/index';
import salesPromotion from './modules/salesPromotion/index';
import grayTest from './modules/grayTest/index';
import row from './modules/row/index';
import footer from './modules/footer/index';
import scrollAnchor from './modules/scrollAnchor/index';
import popupZone from './modules/popupZone/index';
import Vue from 'vue';


function createStoreConfig() {
    return {
        state: () => ({
            oem: false,
            manageMode: false,
            device: 'pc',
            colId: -1,
            extId: -1,
            uiMode: false,
            isFreeVer: false,
            resImageRoot: '',
            resRoot: '',
            siteVer: -1,
            aid: -1,
            fullScreen: false,
            fullScreenOpenFixed: false,
            isDemo: false,
            openSupport: false,
            silentLogin: false,
            openRemoveUrlArgs: false,
            openHtmlUrl: false,
            setReqHttpOnlyCookie: false,
            isShowFkAuthTips: false,
            fkAuthTitle: '',
            lcid: -1,
            curLanCode: '',
            webpOptOpen: true,
            openMsgSearch: true,
            pageName: '',
            isFromQz: false,
            contextMenuDisabled: false,
            inFullNavRowModuleIds: [],
            reqArgs: '',
            siteTipList: [],
            stellarPath: '',
            userHostName: '',
            isBUser: false,
            safeMode: false,
            allowScript: false,
            fullUrl: '',
            isJuly3FreeUser: false,
            siteId: -1,
            wid: -1,
            viewUrl: '',
            welcomePage: {},
            rowCol: [],
            proSiteQueryStr: '',
            jzProUrl: '',
            siteDomain: '',
            homeHost: '',
            isOemSiteHost: false,
            isOemDemoSiteHost: false,
            siteAdmHost: '',
            isExperienceAcct: false,
            hasCheckLvBrowser: false,
            statistics: {},
            openAutoPushPC: false,
            limitUv: false,
            limitDayUv: false,
            rowPics: {},
            isProduction: false,
            siteAutoBaiduPush: false,
            LS: {},
            choiceLanguageForm: {
                open: false,
            },
            tipHttpInCode: {
                open: false,
            },
            responsiveInfo: {},
            modules: {},
            sections: {},
            rows: {},
            beianCode: '',
            newResponsiveConversion: false,
            newMobiResponsiveConversion: false,
            templateNewResponsiveConversion: false,
            templateNewMobiResponsiveConversion: false,
            portalHost: '',
            hasDomainSupportIpv6: false,
            jzCustodyExpireShowAD: false,
            isOpenMResp: false,
            realNameAuth: {},
            debugMode: false,
            transformatinTrackingGlobalModel: {},
            isMaterialLazyLoad: false,
            imageUseThumb: false,
            jzVersion: -1,
            isJzCdnFlowLimit: false,
            isJzDownloadCdnFlowLimit: false,
            defaultCn: false,
            hosts: {},
            memberLogin: false,
            facebookMessengerOpen: false,
            pageId: '',
            isJzDesignRevisionNotice: false,
        }),
        mutations,
        modules: {
            backToTop,
            wx,
            header,
            salesPromotion,
            grayTest,
            row,
            footer,
            scrollAnchor,
            popupZone,
        },
        getters,
    };
}

function initStore(store, data) {
    // 访客态
    store.commit('setFirstPaintData', data);
    const { manageMode, manageData } = data;
    if (!manageMode) {
        return;
    }

    // 管理态
    store.commit('manage/setFirstPaintData', manageData);
}

function createStore(options) {
    Vue.use(Vuex);
    const store = new Vuex.Store(createStoreConfig(options));
    return store;
}

export { createStore, initStore };
